import {Outlet, useNavigate} from "react-router-dom";
import {
    Footer,
    Navbar,
    AppShell,
    Header,
    MediaQuery,
    Burger,
    useMantineTheme,
    ScrollArea,
    Image,
    Container,
    Text, MantineProvider, ColorSchemeProvider, Divider, Tooltip, Flex, Menu
} from "@mantine/core";
import {useEffect, useState} from "react";
import FooterComponent from "../components/Footer.component";
import {ActionIcon} from '@mantine/core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faMoon} from "@fortawesome/pro-regular-svg-icons/faMoon";
import {faSunAlt} from "@fortawesome/pro-regular-svg-icons/faSunAlt";
import {NotificationsProvider} from '@mantine/notifications';
import {ModalsProvider} from '@mantine/modals';
import {useNetwork} from "@mantine/hooks";
import {faWifi} from "@fortawesome/pro-regular-svg-icons/faWifi";
import {faWifiSlash} from "@fortawesome/pro-regular-svg-icons/faWifiSlash";
import {Auth} from "aws-amplify";
import UserButton from "../components/UserButton.component";
import {faHome} from "@fortawesome/pro-regular-svg-icons/faHome";
import NavbarLink from "../components/NavbarLink";
import {faSailboat} from "@fortawesome/pro-regular-svg-icons/faSailboat";
import {faUsers} from "@fortawesome/pro-regular-svg-icons/faUsers";
import {faFileInvoiceDollar} from "@fortawesome/pro-regular-svg-icons/faFileInvoiceDollar";
import {faCalendarAlt} from "@fortawesome/pro-regular-svg-icons/faCalendarAlt";
import {faWarehouse} from "@fortawesome/pro-regular-svg-icons/faWarehouse";
import {faMapMarkerEdit} from "@fortawesome/pro-regular-svg-icons/faMapMarkerEdit";

const Main = ({user, setUser, setAuthState}) => {

    const navigate = useNavigate();
    const theme = useMantineTheme();
    const [opened, setOpened] = useState(false);
    const networkStatus = useNetwork();
    const [colorScheme, setColorScheme] = useState('light');
    const toggleColorScheme = (value) => {
        setColorScheme(value || (colorScheme === 'dark' ? 'light' : 'dark'));
        if(user){
            Auth.currentAuthenticatedUser().then(cu => {
                Auth.updateUserAttributes(cu, {'custom:theme': colorScheme === 'dark' ? 'light' : 'dark'})
                Auth.currentUserInfo();
            })
        }
    }

    useEffect(() => {
        if(user && user.attributes){
            if(user.attributes['custom:theme']){
                setColorScheme(user.attributes['custom:theme']);
            }
        }
    }, [user])

    return <ColorSchemeProvider colorScheme={colorScheme} toggleColorScheme={toggleColorScheme}>
        <MantineProvider theme={{colorScheme}} withGlobalStyles withNormalizeCSS>
            <NotificationsProvider>
                <ModalsProvider>
                    <AppShell
                        navbarOffsetBreakpoint="lg"
                        asideOffsetBreakpoint="sm"
                        navbar={
                            (user && user.attributes) && <Navbar p="sm"
                                            className={'no-print'}
                                            hiddenBreakpoint="lg"
                                            hidden={!opened}
                                            width={{sm: 300, lg: 400}}>
                                <Navbar.Section mt="xs">
                                    <NavbarLink onClick={() => {
                                        navigate('/')
                                        setOpened(false)
                                    }} icon={<FontAwesomeIcon icon={faHome}/>} label={'Home'}/>
                                    <NavbarLink onClick={() => {
                                        navigate('/clients')
                                        setOpened(false)
                                    }} icon={<FontAwesomeIcon icon={faUsers}/>} label={'Armatori'}/>
                                    <NavbarLink onClick={() => {
                                        navigate('/ships')
                                        setOpened(false)
                                    }} icon={<FontAwesomeIcon icon={faSailboat}/>} label={'Imbarcazioni'}/>
                                    <NavbarLink onClick={() => {
                                        navigate('/estimates')
                                        setOpened(false)
                                    }} icon={<FontAwesomeIcon icon={faFileInvoiceDollar}/>} label={'Preventivi'}/>
                                    <NavbarLink onClick={() => {
                                        navigate('/events')
                                        setOpened(false)
                                    }} icon={<FontAwesomeIcon icon={faCalendarAlt}/>} label={'Calendario'}/>
                                    <NavbarLink onClick={() => {
                                        navigate('/warehouse')
                                        setOpened(false)
                                    }} icon={<FontAwesomeIcon icon={faWarehouse}/>} label={'Magazzino'}/>
                                    <NavbarLink onClick={() => {
                                        navigate('/editMap')
                                        setOpened(false)
                                    }} icon={<FontAwesomeIcon icon={faMapMarkerEdit}/>} label={'Modifica mappa'}/>
                                </Navbar.Section>
                                <Navbar.Section grow component={ScrollArea} mx="-xs" px="xs"  >
                                    {/* scrollable content here */}
                                </Navbar.Section>
                                <Navbar.Section>
                                    <Divider/>
                                    <Menu withArrow transition="rotate-right"
                                          width={300}
                                          className={'no-print'}
                                          transitionDuration={150} position={'top-end'}>
                                        <Menu.Target>
                                            <UserButton user={user}/>
                                        </Menu.Target>

                                        <Menu.Dropdown >
                                            <Menu.Item onClick={() => {
                                                Auth.signOut().then(() => {
                                                    setAuthState('SIGN_IN')
                                                    setUser(undefined)
                                                })
                                            }}>Logout</Menu.Item>
                                        </Menu.Dropdown>
                                    </Menu>
                                </Navbar.Section>
                            </Navbar>
                        }
                        footer={
                            user && <Footer height={80} p="md"  className={'no-print'}>
                                <FooterComponent/>
                            </Footer>
                        }
                        header={
                            user && <Header height={{base: 50, md: 70}} p="md" className={'no-print'}>
                                <div style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    height: '100%',
                                    justifyContent: 'space-between'
                                }}>
                                    <MediaQuery largerThan="lg" styles={{display: 'none'}}>
                                        <Burger
                                            className={'no-print'}
                                            opened={opened}
                                            onClick={() => setOpened((o) => !o)}
                                            size="sm"
                                            color={theme.colors.gray[6]}
                                            mr="xl"
                                        />
                                    </MediaQuery>

                                    <Image style={{margin: 'auto', width: 90, marginTop: '-10px'}} src={'/logo-arnav.png'}/>

                                    <Flex align={"center"}>
                                        <Text size={"sm"} mr={16}>
                                            <FontAwesomeIcon
                                                style={{marginRight: 12}}
                                                icon={networkStatus.online ? faWifi : faWifiSlash}
                                                color={networkStatus.online ? 'teal' : 'red'}/>
                                            {networkStatus.online ? 'Online' : 'Offline'}
                                        </Text>
                                        <Tooltip withinPortal
                                            label={`Attiva modalità ${colorScheme === 'light' ? 'notturna' : 'diurna'}`}>
                                            <ActionIcon variant="outline"
                                                        onClick={() => toggleColorScheme()}>
                                                <FontAwesomeIcon icon={colorScheme === 'light' ? faMoon : faSunAlt}/>
                                            </ActionIcon>
                                        </Tooltip>
                                    </Flex>
                                </div>
                            </Header>
                        }
                    >
                        <Container style={{maxWidth: '100%'}}>
                            <Outlet/>
                        </Container>
                    </AppShell>
                </ModalsProvider>
            </NotificationsProvider>
        </MantineProvider>
    </ColorSchemeProvider>
}


export default Main;