import {Button, Group, Modal, Select, Stack, Text, Textarea, TextInput} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from '@mantine/form';
import React, {useEffect, useState} from "react";
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";
import {showNotification} from "@mantine/notifications";
import {faLoader} from "@fortawesome/pro-regular-svg-icons/faLoader";
import {faSearch} from "@fortawesome/pro-regular-svg-icons/faSearch";
import {DatePicker} from "@mantine/dates";
import {dateToAwsDate} from "../../../functions/dateToAwsDate";
import {isAWSDate} from "../../../functions/isAWSDate";
import {faWarning} from "@fortawesome/pro-regular-svg-icons/faWarning";
import {ShipService} from "../../../services/shipService";
import {EventService} from "../../../services/eventService";
import {genericError} from "../../../functions/genericError";
import {openConfirmModal} from "@mantine/modals";
import {faTrash} from "@fortawesome/pro-regular-svg-icons/faTrash";


const ModalEventComponent = (props) => {

    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [ships, setShips] = useState([])
    const [deletingEventId, setDeletingEventId] = useState(false)

    const form = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        criteriaMode: "firstError",
        shouldFocusError: true,
        initialValues: {
            event_date: new Date()
        }
    });


    useEffect(() => {
        if (!props.event) return;

        if (props.event?.id) {
            form.setValues({
                event_date: new Date(props.event.event_date),
                action: props.event.action,
                ship_id: props.event.ship_id,
                notes: props.event.notes
            })
        } else {
            setIsLoading(true)
            ShipService.list().then((r) => {
                const mappedR = r.map(s => {
                    return {
                        value: s.id.toString(),
                        label: s.name
                    }
                });
                setShips(mappedR)
            }).finally(() => setIsLoading(false))

            form.setValues({
                event_date: props.event.event_date,
                action: props.event.action,
                ship_id: props.event.ship_id,
                notes: props.event.notes
            })
        }
        return () => {
            setShips([])
            form.reset();
        }
    }, [props.event])


    const formOnSubmit = async (values) => {

        values.event_date = dateToAwsDate(values.event_date)

        if (!isAWSDate.test(values.event_date)) {
            showNotification({
                color: 'orange',
                icon: <FontAwesomeIcon icon={faWarning}/>,
                message: 'Formato data errato',
            })
            setIsSaving(false)
            return;
        }
        setIsSaving(true)
        if (props.event?.id) {
            EventService.update(values, props.event.id)
                .then((r) => {
                    showNotification({
                        color: 'teal',
                        icon: <FontAwesomeIcon icon={faCheck}/>,
                        message: 'Evento aggiornato',
                    })
                    props.updateEvents('update', r)
                    props.setEvent(undefined)
                })
                .catch(genericError)
                .finally(() => setIsSaving(false))
        } else {
            EventService.create(values)
                .then((r) => {
                    showNotification({
                        color: 'teal',
                        icon: <FontAwesomeIcon icon={faCheck}/>,
                        message: 'Evento salvato',
                    })
                    props.updateEvents('create', r)
                    props.setEvent(undefined)
                })
                .catch(genericError)
                .finally(() => setIsSaving(false))
        }
    }


    const confirmDeleteEvent = (event) => openConfirmModal({
        title: 'Conferma eliminazione',
        children: (
            <Text size="sm">
                Sei sicuro di voler eliminare questo evento {event.action}?
            </Text>
        ),
        labels: {confirm: 'Conferma', cancel: 'Annulla'},
        zIndex: 300,
        withinPortal: true,
        confirmProps: {color: 'red'},
        onCancel: () => console.log('Azione annullata'),
        onConfirm: () => deleteEventFunction(event),
    });

    const deleteEventFunction = async (event) => {
        setDeletingEventId(true)
        EventService.delete(event.id)
            .then((r) => {
                showNotification({
                    color: 'teal',
                    icon: <FontAwesomeIcon icon={faTrash}/>,
                    message: 'Evento eliminato',
                })
                props.updateEvents('delete', r)
            })
            .catch(genericError)
            .finally(() => {
                setDeletingEventId(false)
            })
    }

    return <Modal
        size={'90%'}
        opened={props.event}
        lockScroll={false}
        onClose={() => props.setEvent(undefined)}
        title={props.event?.id ? `Modifica evento` : 'Crea nuovo evento'}
    >

        <form onSubmit={form.onSubmit(formOnSubmit)}>

            <Stack>
                <DatePicker
                    locale={'it'}
                    label="Data evento"
                    inputFormat="YYYY-MM-DD"
                    required
                    withinPortal={true}
                    placeholder=""
                    {...form.getInputProps('event_date')}
                />

                {props.event?.id ?
                    <TextInput readOnly
                               label="Imbarcazione"
                               value={props.event.ship.name}/>
                    :
                    <Select
                        searchable
                        label="Imbarcazione"
                        limit={10}
                        icon={<FontAwesomeIcon icon={isLoading ? faLoader : faSearch} spin={isLoading}/>}
                        data={ships}
                        {...form.getInputProps('ship_id')}
                    />
                }

                <Select
                    label="Tipo di evento"
                    data={[
                        {value: 'alaggio', label: 'Alaggio'},
                        {value: 'varo', label: 'Varo'},
                        {value: 'generico', label: 'Generico'},
                    ]}
                    required
                    {...form.getInputProps('action')}
                />

                <Textarea label="Notes"
                          {...form.getInputProps('notes')}/>
            </Stack>

            <Group position="right" mt="md">
                {props.event?.id &&
                    <Button type="button"
                            color={'red'}
                            onClick={() => confirmDeleteEvent(props.event)}
                            loading={deletingEventId}
                            disabled={deletingEventId}>
                        Elimina
                    </Button>
                }
                <Button type="submit"
                        loading={isSaving}
                        disabled={isSaving}>
                    Salva
                </Button>
            </Group>
        </form>

    </Modal>
}
export default ModalEventComponent;
