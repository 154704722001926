import {Route, Routes, useLocation, useNavigate} from "react-router-dom";
import Dashboard from "../pages/Dashboard/Dashboard";
import Ships from "../pages/Ships/Ships";
import Main from "./Main";
import {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import Login from "../pages/Auth/Login";
import ResetPassword from "../pages/Auth/ResetPassword";
import ForgotPassword from "../pages/Auth/ForgotPassword";
import Page404 from "../pages/Page404";
import Clients from "../pages/Clients/Clients";
import ShipDocuments from "../pages/Ships/ShipDocuments";
import Events from "../pages/Events/Events";
import Estimates from "../pages/Estimates/Estimates";
import EstimateForm from "../pages/Estimates/EstimateForm";
import EstimatePreview from "../pages/Estimates/EstimatePreview";
import Map from "../pages/Map/Map";
import Warehouse from "../pages/Warehouse/Warehouse";
import MapEdit from "../pages/Map/MapEdit";


const AuthRouteWrapper = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const [user, setUser] = useState();
    const [authState, setAuthState] = useState('LOADING')


    useEffect(() => {
        if (!user) {
            Auth.currentUserInfo().then(u => {
                console.log('retrieve current user info', u)
                if (u) {
                    setAuthState('SIGNED_IN')
                    setUser(u)
                } else {
                    setAuthState('SIGN_IN')
                }
            }).catch(e => {
                setAuthState('SIGN_IN')
            })
        }
        if (['/login', 'forgot_password', 'mfa'].includes(location.pathname)) {
            navigate('/')
        }
    }, [location, user])

    const protectedRoute = (element) => {
        switch (authState) {
            case 'SIGN_IN':
                return <Login setUser={setUser} setAuthState={setAuthState}/>
            case 'FORCE_CHANGE_PW':
                return <ResetPassword user={user} setAuthState={setAuthState}/>
            case 'CONFIRM_USER_REGISTRATION':
                return <>Todo</>
            case 'SIGN_UP':
                return <>Todo</>
            case 'MFA':
                return <>Todo</>
            case 'ASK_RESET_PW':
                return <ForgotPassword setUser={setUser} setAuthState={setAuthState}/>
            case 'SETUP_MFA':
                return <>Todo</>
            case 'SIGNED_IN':
                return element;
            default:
                return <>Loading</>;
        }
    }


    return <Routes>
        <Route path="/" element={<Main user={user} setUser={setUser} setAuthState={setAuthState}/>}>
            <Route path={'login'} exact element={<Login setUser={setUser} setAuthState={setAuthState} isRoute/>}/>
            <Route path={'forgot_password'} exact
                   element={<ForgotPassword setUser={setUser} setAuthState={setAuthState} isRoute/>}/>
            <Route path={'mfa'} exact/>
            <Route path={'reset_password'} exact/>


            <Route index element={protectedRoute(<Dashboard/>)}/>
            <Route path="clients" exact element={protectedRoute(<Clients/>)}/>
            <Route path="ships" exact element={protectedRoute(<Ships/>)}/>
            <Route path="ship/:shipId/documents" exact element={protectedRoute(<ShipDocuments/>)}/>
            <Route path="events" exact element={protectedRoute(<Events/>)}/>
            <Route path="map" exact element={protectedRoute(<Map/>)}/>
            <Route path="estimates" exact element={protectedRoute(<Estimates/>)}/>
            <Route path="estimate" exact element={protectedRoute(<EstimateForm/>)}/>
            <Route path="estimate/pre" exact element={<EstimatePreview/>}/>
            <Route path="warehouse" exact element={<Warehouse/>}/>
            <Route path="editMap" exact element={<MapEdit/>}/>

            <Route path="*" element={<Page404/>}/>
        </Route>
    </Routes>
}


export default AuthRouteWrapper;