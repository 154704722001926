import {
    TextInput,
    Button,
    Group,
    Center,
    Box,
    Anchor,
    Container,
    Paper,
    Title,
    Text,
    createStyles, Stepper, PasswordInput, Stack, Progress, List
} from '@mantine/core';
import {useForm} from '@mantine/form';
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import {useNavigate} from "react-router-dom";
import {faInputNumeric} from "@fortawesome/pro-regular-svg-icons/faInputNumeric";
import {faEnvelope} from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import {faCircleCheck} from "@fortawesome/pro-regular-svg-icons/faCircleCheck";
import {showNotification} from "@mantine/notifications";
import {faCross} from "@fortawesome/pro-regular-svg-icons/faCross";
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";
import {Auth} from "aws-amplify";
import {faCircle} from "@fortawesome/pro-regular-svg-icons/faCircle";


const useStyles = createStyles((theme) => ({
    title: {
        fontSize: 26,
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    controls: {
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column-reverse',
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
}));


const ForgotPassword = ({setUser, setAuthState, isRoute = false}) => {

    const navigate = useNavigate();
    const [status, setStatus] = useState(1)

    const [percentage, setPercentage] = useState(0);
    const [validDigit, setValidDigit] = useState(false);
    const [validUppercase, setValidUppercase] = useState(false);
    const [validLowercase, setValidLowercase] = useState(false);
    const [validSpecialCharacters, setValidSpecialCharacters] = useState(false);
    const [validLen, setValidLen] = useState(false);

    const [validPassword, setValidPassword] = useState(false);

    const form = useForm({
        initialValues: {
            email: '',
            newPassword: '',
            confirmNewPassword: '',
            code: ''
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Email non valida'),
            newPassword: (value) => (status === 2 ? validPassword : true) ? null : 'La lunghezza minima della password è di 8 caratteri',
            confirmNewPassword: (value) => (status === 2 ? form.values.newPassword === form.values.confirmNewPassword : true) ? null : 'La lunghezza minima della password è di 8 caratteri',
            code: (value) => (status === 2 ? value.length === 6 : true) ? null : 'La lunghezza minima della codice è di 6 caratteri',
        },
    });

    const [isLoading, setIsLoading] = useState(false)
    const {classes} = useStyles()

    useEffect(() => {
        let newPercentage = 0;

        const password = form.values.newPassword;

        if (password && password.length > 0) {
            const newValidDigit = new RegExp(/\d/).test(password);
            const newValidUppercase = new RegExp(/[A-Z]/).test(password);
            const newValidLowercase = new RegExp(/[a-z]/).test(password);
            const newValidSpecialCharacters = new RegExp(/\W/).test(password);
            const newValidLen = password.length >= 9 && password.length <= 99;

            setValidDigit(newValidDigit); // async
            setValidUppercase(newValidUppercase);  // async
            setValidLowercase(newValidLowercase);  // async
            setValidSpecialCharacters(newValidSpecialCharacters);  // async
            setValidLen(newValidLen);  // async

            setValidPassword(
                newValidDigit &&
                newValidUppercase &&
                newValidLowercase &&
                newValidSpecialCharacters &&
                newValidLen
            )

            newPercentage = (newValidDigit ? 0.2 : 0) +
                (newValidUppercase ? 0.2 : 0) +
                (newValidLowercase ? 0.2 : 0) +
                (newValidSpecialCharacters ? 0.2 : 0) +
                (newValidLen ? 0.2 : 0)

        } else {
            setValidDigit(false);
            setValidUppercase(false);
            setValidLowercase(false);
            setValidSpecialCharacters(false);
            setValidLen(false);
        }

        setPercentage(newPercentage*100); // async
    }, [form.values.newPassword])


    const onFormSubmit = (values) => {
        setIsLoading(true)
        const email = values.email;
        const verificationCode = values.code;
        const newPassword = values.newPassword;

        if (status === 1) {
            Auth.forgotPassword(email)
                .then(() => {
                    showNotification({
                        color: 'teal',
                        icon: <FontAwesomeIcon icon={faCheck}/>,
                        title: 'Codice inviato',
                        message: 'Controlla la tua casella di posta ed inserisci il codice ricevuto nel riquadro sottostante'
                    })
                    setStatus(2)
                })
                .catch(err => {
                    console.error('forgot password failed', err)
                    showNotification({
                        color: 'red',
                        icon: <FontAwesomeIcon icon={faCross}/>,
                        title: 'Errore',
                        message: err.message
                    })
                })
                .finally(() => {
                    setIsLoading(false)
                });
        }
        // finally
        Auth.forgotPasswordSubmit(
            email,
            verificationCode,
            newPassword
        )
            .then((res) => {
                showNotification({
                    color: 'teal',
                    icon: <FontAwesomeIcon icon={faCheck}/>,
                    title: 'Password aggiornata',
                    message: 'Adesso puoi procedere alla login'
                })
                setAuthState('SIGN_IN');
            })
            .catch(err => {
                console.error('forgot password failed', err)
                showNotification({
                    color: 'red',
                    icon: <FontAwesomeIcon icon={faCross}/>,
                    title: 'Errore',
                    message: err.message
                })
            })
            .finally(() => {
                setIsLoading(false)
            });

    }


    return <Container size={460} my={30}>
        <Title className={classes.title} align="center">
            Password scaduta o dimenticata?
        </Title>
        <Text color="dimmed" size="sm" align="center">
            Inserisci la tua email per ricevere il codice di reset.
        </Text>


        <Paper withBorder shadow="md" p={30} mt={30} radius="md">
            <Stepper iconSize={42} active={status - 1} mb={12}>
                <Stepper.Step label="Step 1" description="Richiedi codice"/>
                <Stepper.Step label="Step 2" description="Crea nuova password"/>
            </Stepper>


            <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
                <Stack>
                    <TextInput label="Email" readOnly={status === 2} placeholder="you@gmail.com"
                               required   {...form.getInputProps('email')}/>

                    {status === 2 && <>
                        <TextInput label="Codice di conferma" placeholder="032154"
                                   required   {...form.getInputProps('code')}/>
                        <div>
                            <PasswordInput label="Nuova password"
                                           description={<>
                                               <Text>La password deve contenere:</Text>
                                               <List size={'xs'}>
                                                   <List.Item icon={<FontAwesomeIcon icon={validLen ? faCircleCheck : faCircle} color={validLen ? 'teal' : ''}/>}>8 caratteri</List.Item>
                                                   <List.Item icon={<FontAwesomeIcon icon={validUppercase ? faCircleCheck : faCircle} color={validUppercase ? 'teal' : ''}/>}>almeno una lettera maiuscola</List.Item>
                                                   <List.Item icon={<FontAwesomeIcon icon={validLowercase ? faCircleCheck : faCircle} color={validLowercase ? 'teal' : ''}/>}>almeno una lettera minuscola</List.Item>
                                                   <List.Item icon={<FontAwesomeIcon icon={validDigit ? faCircleCheck : faCircle} color={validDigit ? 'teal' : ''}/>}>almeno una numero</List.Item>
                                                   <List.Item icon={<FontAwesomeIcon icon={validSpecialCharacters ? faCircleCheck : faCircle} color={validSpecialCharacters ? 'teal' : ''}/>}>almeno un carattere speciale</List.Item>
                                               </List>
                                           </>}
                                           required
                                           {...form.getInputProps('newPassword')}/>
                            <Progress color="cyan" radius="xs" size="sm" value={percentage}/>
                        </div>
                        <PasswordInput label="Conferma nuova password"
                                       required   {...form.getInputProps('confirmNewPassword')}/>
                    </>
                    }
                </Stack>

                <Group position="apart" mt="lg" className={classes.controls}>
                    <Anchor color="dimmed" size="sm" className={classes.control} onClick={() => {
                        if (isRoute) {
                            navigate('/login')
                        } else {
                            setAuthState('SIGN_IN')
                        }
                    }}>
                        <Center inline>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                            <Box ml={5}>Torna alla login</Box>
                        </Center>
                    </Anchor>
                    <Button className={classes.control}
                            leftIcon={<FontAwesomeIcon icon={status === 1 ? faEnvelope : faCircleCheck}/>}
                            type={'submit'}
                            loading={isLoading}
                            disabled={isLoading}>
                        {status === 1 ? 'Invia codice' : 'Imposta nuova password'}
                    </Button>
                </Group>
            </form>

            <Group position="center" mt="lg" className={classes.controls}>
                <Anchor color="dimmed" size="sm" className={classes.control} onClick={() => {
                    const valid = form.validate();
                    if (!valid.hasErrors) {
                        setStatus(status === 1 ? 2 : 1)
                    }
                }}>
                    <Center inline>
                        <FontAwesomeIcon icon={faInputNumeric}/>
                        <Box
                            ml={5}>{status === 1 ? 'Ho già ricevuto il codice di verifica' : 'Non ho ricevuto il codice di conferma'}</Box>
                    </Center>
                </Anchor>
            </Group>
        </Paper>
    </Container>
}
export default ForgotPassword