import {Button, Divider, Flex, Group, Modal, NumberInput, Select, Stack, TextInput, Tooltip} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from '@mantine/form';
import {useEffect, useState} from "react";
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";
import {showNotification} from "@mantine/notifications";
import {ShipService} from "../../../services/shipService";
import {genericError} from "../../../functions/genericError";


const ModalShipComponent = (props) => {

    const [isSaving, setIsSaving] = useState(false)
    const form = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        criteriaMode: "firstError",
        shouldFocusError: true
    });


    useEffect(() => {
        if (!props.ship) return;
        form.setValues({
            name: props.ship.name,
            model: props.ship.model,
            flag: props.ship.flag,
            length: props.ship.length,
            width: props.ship.width,
            height: props.ship.height,
            brand: props.ship.brand,
            imo: props.ship.imo,
            propulsion: props.ship.propulsion,
            engine: props.ship.engine,
            commander: props.ship.commanders?.length > 0 ? props.ship.commanders[0].name : '',
            phone: props.ship.commanders?.length > 0 ? props.ship.commanders[0].phone : '',
            slot: props.ship.slot
        })
    }, [props.ship])


    const formOnSubmit = async (values) => {
        setIsSaving(true)
        if (props.ship.id) {
            ShipService.update(values, props.ship.id)
                .then((r) => {
                    showNotification({
                        color: 'teal',
                        icon: <FontAwesomeIcon icon={faCheck}/>,
                        message: 'Imbarcazione aggiornata',
                    })
                    props.updateShips('update', r)
                    props.setShip(undefined)

                })
                .catch(genericError)
                .finally(() => {
                    setIsSaving(false)
                })
        } else {
            ShipService.create(values)
                .then((r) => {

                    showNotification({
                        color: 'teal',
                        icon: <FontAwesomeIcon icon={faCheck}/>,
                        message: 'Imbarcazione creata',
                    })
                    props.updateShips('create', r)
                    props.setShip(undefined)

                })
                .catch(genericError)
                .finally(() => {
                    setIsSaving(false)
                })
        }
    }


    return <Modal
        size={'90%'}
        opened={props.ship}
        lockScroll={false}
        onClose={() => props.setShip(undefined)}
        title={props.ship?.id ? `Modifica imbarcazione: ${props.ship?.name}` : 'Crea nuova imbarcazione'}
    >

        <form onSubmit={form.onSubmit(formOnSubmit)}>

            <Stack>
                <TextInput
                    label="Nome"
                    required
                    placeholder="El' Han"
                    {...form.getInputProps('name')}
                />

                <TextInput
                    style={{flex: 0.45}}
                    label="IMO Numero identificativo"
                    placeholder=""
                    {...form.getInputProps('imo')}
                />


                <Flex justify={'space-between'}>
                    <TextInput
                        style={{flex: 0.45}}
                        label="Bandiera"
                        placeholder="IT"
                        {...form.getInputProps('flag')}
                    />

                    <NumberInput
                        style={{flex: 0.45}}
                        label="Lunghezza"
                        placeholder="13.51 metri"
                        {...form.getInputProps('length')}
                    />
                </Flex>


                <Flex justify={'space-between'}>
                    <NumberInput
                        label="Larghezza"
                        placeholder="4.18 metri"
                        {...form.getInputProps('width')}
                    />
                </Flex>


                <Flex justify={'space-between'}>
                    <TextInput
                        style={{flex: 0.45}}
                        label="Matricola"
                        placeholder=""
                        {...form.getInputProps('brand')}
                    />


                    <TextInput
                        label="Marca"
                        style={{flex: 0.45}}
                        placeholder="Elan Impression 45.1"
                        {...form.getInputProps('model')}
                    />
                </Flex>


                <TextInput
                    label="Propulsione"
                    placeholder="Vela"
                    {...form.getInputProps('propulsion')}
                />

                <TextInput
                    label="Motore"
                    placeholder="Yanmar 4JH45 33.1 kW / 45 mhp"
                    {...form.getInputProps('engine')}
                />


                <Tooltip label={'Modifica il posto tramite la mappa'}>
                    <TextInput
                        label="Numero di posto"
                        disabled
                        readOnly
                        placeholder="A1"
                        {...form.getInputProps('slot')}
                    />
                </Tooltip>

                <Divider/>
                <TextInput
                    label="Comandante"
                    placeholder="John Brandibuck"
                    {...form.getInputProps('commander')}
                />

                <TextInput
                    label="Telefono comandante"
                    placeholder="+39 123 321 456"
                    {...form.getInputProps('phone')}
                />
            </Stack>

            <Group position="right" mt="md">
                <Button type="submit"
                        loading={isSaving}
                        disabled={isSaving}>
                    Salva
                </Button>
            </Group>
        </form>

    </Modal>
}
export default ModalShipComponent;
