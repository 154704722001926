import {Button, Checkbox, Group, Modal, Select, Stack, Textarea, TextInput} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from '@mantine/form';
import {useEffect, useState} from "react";
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";
import {showNotification} from "@mantine/notifications";
import {genericError} from "../../../functions/genericError";
import {faLoader} from "@fortawesome/pro-regular-svg-icons/faLoader";
import {faSearch} from "@fortawesome/pro-regular-svg-icons/faSearch";
import {ShipOwnerServicee} from "../../../services/shipOwnerService";
import {DatePicker} from "@mantine/dates";
import {dateToAwsDate} from "../../../functions/dateToAwsDate";
import {isAWSDate} from "../../../functions/isAWSDate";
import {faWarning} from "@fortawesome/pro-regular-svg-icons/faWarning";
import {EstimateService} from "../../../services/estimateService";
import {ShipService} from "../../../services/shipService";
import {format} from 'date-fns'
const ModalEstimateComponent = (props) => {

    const [isSaving, setIsSaving] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const [isLoading2, setIsLoading2] = useState(false)
    const [shipOwners, setShipOwners] = useState([])
    const [ships, setShips] = useState([])

    const form = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        criteriaMode: "firstError",
        shouldFocusError: true
    });

    useEffect(() => {
        if(!props.estimate) {
            return;
        }
        setIsLoading(true)
        setIsLoading2(true)

        ShipService.list().then((r) => {
            const mappedR = r.map(s => {
                return {
                    value: s.id.toString(),
                    label: s.name
                }
            });
            setShips(mappedR)
            if (props.estimate.ship_owner_id) {
                const selectedShip = mappedR.find(so => so.value === props.estimate.ship_id.toString());
                form.setValues({ship_id: selectedShip.value})
            }

        })
            .catch(genericError)
            .finally(() => {
                setIsLoading2(false)
            })

        ShipOwnerServicee.list().then((r) => {
            const mappedR = r.map(s => {
                return {
                    value: s.id.toString(),
                    label: s.given_name ?? s.surname + ' ' + s.name
                }
            });
            setShipOwners(mappedR)
            if (props.estimate.ship_owner_id) {
                const selectedOwner = mappedR.find(so => so.value === props.estimate.ship_owner_id.toString());
                form.setValues({ship_owner_id: selectedOwner.value})
            }
        })
            .catch(genericError)
            .finally(() => setIsLoading(false))


        const d = new Date();
        d.setDate(d.getDate() + 15);

        const valid_until = props.estimate && props.estimate.valid_until ? new Date(props.estimate.valid_until) : d;

        form.setValues({
            number: props.estimate.number,
            name: props.estimate.name,
            valid_until: valid_until,
            status: props.estimate.status,
            notes: props.estimate.notes ?? '',
            description: props.estimate.description ?? '',
            ship_owner_id: props.estimate.ship_owner_id,
            ship_id: props.estimate.ship_id,
            is_total_hidden: props.estimate.is_total_hidden
        })
        return () => {
            setShipOwners([])
            form.reset();
        }
    }, [props.estimate])

    const formOnSubmit = async (values) => {

        const c = structuredClone(values)
        c.valid_until = format(values.valid_until, 'yyyy-MM-dd');
        console.log('c', c)
        if(!c.is_total_hidden){
            delete c.is_total_hidden;
        }

        setIsSaving(true)
        if (props.estimate.id) {
            EstimateService.update(c, props.estimate.id)
                .then((r) => {
                    showNotification({
                        color: 'teal',
                        icon: <FontAwesomeIcon icon={faCheck}/>,
                        message: 'Preventivo aggiornato',
                    })
                    props.updateEstimates('update', r)
                    props.setEstimate(undefined)

                })
                .catch(genericError)
                .finally(() => {
                    setIsSaving(false)
                })
        } else {
            EstimateService.create(c)
                .then((r) => {

                    showNotification({
                        color: 'teal',
                        icon: <FontAwesomeIcon icon={faCheck}/>,
                        message: 'Preventivo creato',
                    })
                    props.updateEstimates('create', r)
                    props.setEstimate(undefined)

                })
                .catch(genericError)
                .finally(() => {
                    setIsSaving(false)
                })
        }
    }


    return <Modal
        size={'90%'}
        style={{maxWidth: 600, margin: 'auto'}}
        opened={props.estimate}
        lockScroll={false}
        onClose={() => props.setEstimate(undefined)}
        title={props.estimate?.id ? `Modifica preventivo: ${props.estimate?.name ?? props.estimate?.id}` : 'Crea nuovo preventivo'}
    >

        <form onSubmit={form.onSubmit(formOnSubmit)}>

            <Stack>

                <TextInput
                    label="Numero preventivo"
                    description="Opzionale, il progressivo è basato sull'ID autogenerato."
                    {...form.getInputProps('number')}
                />

                <TextInput
                    label="Oggetto del preventivo"
                    {...form.getInputProps('name')}
                />

                <Select
                    searchable
                    required
                    withAsterisk
                    limit={10}
                    label="Armatore"
                    icon={<FontAwesomeIcon icon={isLoading ? faLoader : faSearch} spin={isLoading}/>}
                    data={shipOwners}
                    {...form.getInputProps('ship_owner_id')}
                />

                <Select
                    searchable
                    required
                    withAsterisk
                    limit={10}
                    label="Imbarcazione"
                    icon={<FontAwesomeIcon icon={isLoading2 ? faLoader : faSearch} spin={isLoading2}/>}
                    data={ships}
                    {...form.getInputProps('ship_id')}
                />

                <Select
                    label="Status"
                    required
                    withAsterisk
                    defaultValue={'draft'}
                    data={[
                        {
                            value: 'draft',
                            label: 'Bozza',
                        },
                        {
                            value: 'sent',
                            label: 'Inviato',
                        },
                        {
                            value: 'accepted',
                            label: 'Accettato',
                        }
                    ]}
                    {...form.getInputProps('status')}
                />


                <DatePicker
                    locale={'it'}
                    label="Valido fino al"
                    inputFormat="YYYY-MM-DD"
                    withAsterisk
                    required
                    withinPortal={true}
                    placeholder=""
                    {...form.getInputProps('valid_until')}
                />


                <Textarea
                    maxLength={6000}
                    autosize
                    label="Note (non visibile)"
                    {...form.getInputProps('notes')}
                />
                <Textarea
                    maxLength={6000}
                    autosize
                    label="Suggerimento (visibile a lato del nome imbarcazione)"
                    {...form.getInputProps('description')}
                />

                <Checkbox
                    {...form.getInputProps('is_total_hidden', { type: "checkbox" })}
                    label={'Nascondi totale'}/>


            </Stack>


            <Group position="right" mt="md">
                <Button type="submit"
                        loading={isSaving || isLoading || isLoading2}
                        disabled={isSaving || isLoading || isLoading2}>
                    {isLoading || isLoading2 ? 'Recupero informazioni' : 'Salva'}
                </Button>
            </Group>
        </form>

    </Modal>
}
export default ModalEstimateComponent;
