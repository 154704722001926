import {AxiosInterceptor} from "./AxiosInterceptor";

export class MapService {


    static getMap = async () => {
        const url = `${process.env.REACT_APP_URL}/map`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.get(url).then((res) => {
                    return res.data;
                });
            });
    }

    static updateMap = async (data) => {
        const url = `${process.env.REACT_APP_URL}/map`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.post(url, data).then((res) => {
                    return res.data;
                });
            });
    }

    static updateShipSlot = async (data) => {
        const url = `${process.env.REACT_APP_URL}/map/updateShipSlot`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.post(url, data).then((res) => {
                    return res.data;
                });
            });
    }

    static freeSlot = async (data) => {
        const url = `${process.env.REACT_APP_URL}/map/freeSlot`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.post(url, data).then((res) => {
                    return res.data;
                });
            });
    }
}