import {AxiosInterceptor} from "./AxiosInterceptor";

export class ShipService {

    static get = async (id) => {
        const url = `${process.env.REACT_APP_URL}/ship/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.get(url).then((res) => {
                    return res.data;
                });
            });
    }

    static list = async () => {
        const url = `${process.env.REACT_APP_URL}/ships`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.get(url).then((res) => {
                    return res.data;
                });
            });
    }

    static create = async (data) => {
        const url = `${process.env.REACT_APP_URL}/ship`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.post(url, data).then((res) => {
                    return res.data;
                });
            });
    }

    static update = async (data, id) => {
        const url = `${process.env.REACT_APP_URL}/ship/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.put(url, data).then((res) => {
                    return res.data;
                });
            });
    }

    static delete = async (id) => {
        const url = `${process.env.REACT_APP_URL}/ship/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.delete(url).then((res) => {
                    return res.data;
                });
            });
    }

    static associateClients = async (id, clients) => {
        const url = `${process.env.REACT_APP_URL}/ship/${id}/associateClients`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.post(url, clients).then((res) => {
                    return res.data;
                });
            });
    }

    static removeClient = async (id, shipOwnerId) => {
        const url = `${process.env.REACT_APP_URL}/ship/${id}/${shipOwnerId}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.delete(url).then((res) => {
                    return res.data;
                });
            });
    }

}