import {
    BrowserRouter,
} from "react-router-dom";

import AuthRouteWrapper from "./layouts/AuthRouteWrapper";

function App() {


    return (<>
            <BrowserRouter>
                <AuthRouteWrapper/>
            </BrowserRouter>
        </>
    );
}

export default App;
