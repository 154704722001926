import {useEffect, useRef, useState} from "react";
import {
    ActionIcon,
    Button, Card, Drawer, LoadingOverlay, Select, Text, TextInput,
} from '@mantine/core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react'
import {fabric} from 'fabric';
import {useTheme} from "@emotion/react";
import {faMagnifyingGlassMinus} from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlassMinus";
import {faMagnifyingGlassPlus} from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlassPlus";
import {faSave} from "@fortawesome/pro-regular-svg-icons/faSave";
import {MapService} from "../../services/mapService";
import {genericError} from "../../functions/genericError";
import {showNotification} from "@mantine/notifications";
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";
import {faCross} from "@fortawesome/pro-regular-svg-icons/faCross";
import {faChevronLeft} from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import {faChevronRight} from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import {faChevronUp} from "@fortawesome/pro-regular-svg-icons/faChevronUp";
import {faChevronDown} from "@fortawesome/pro-regular-svg-icons/faChevronDown";


const MapEdit = () => {

    const isLight = useTheme().colorScheme === 'light';
    const primaryColor = useTheme().colors.gray[isLight ? 9 : 1];
    const secondaryColor = useTheme().colors.cyan[isLight ? 9 : 1];

    const [isLoading, setIsLoading] = useState(false)

    const {editor, onReady} = useFabricJSEditor()
    const [fetched, setFetched] = useState(false)


    const [isEditing, setIsEditing] = useState(false)
    const [selected, setSelected] = useState()

    const [isSaving, setIsSaving] = useState(false)

    const [selectedValue, setSelectedValue] = useState('')
    const cardRef = useRef();

    useEffect(() => {
        if (fabric && editor && !fetched && onReady) {
            setFetched(true)
            fetchDocks();
        }
    }, [fabric, editor, onReady])

    useEffect(() => {
        if (fabric && editor && fetched) {
            fetchDocks();
        }
    }, [isLight])

    const loadMap = (r) => {
        fabric.loadSVGFromURL('/map.svg', function (objects, options) {
            editor.canvas.setHeight(600);
            const items = fabric.util.groupSVGElements(objects, options);
            items.hasControls = false;
            items.selectable = false;
            items._objects.forEach(obj => {
                obj.stroke = primaryColor;
                obj.fill = 'transparent';
            });
            editor.canvas.add(items);


            fabric.util.enlivenObjects(r.boxes.map(o => JSON.parse(o.content)), function (o2) {
                o2.forEach(function (o, index) {
                    o.hasControls = true;
                    o.selectable = true;
                    editor.canvas.add(o);
                });
            });
            editor.canvas.setZoom(0.25);
            editor.canvas.allowTouchScrolling = true;
            editor.canvas.renderAll();

            editor.canvas.on({
                'selection:updated': handleElement,
                'selection:created': handleElement,
                'selection:cleared': handleElement
            });
        });
    }

    const fetchDocks = async () => {
        setIsLoading(true)
        MapService.getMap().then(r => {
            loadMap(r)
        })
            .catch((e) => {
                genericError(e)
            })
            .finally(() => setIsLoading(false))
    }

    const zoomFoo = (x) => {
        let f = editor.canvas.getZoom();
        if (x === 0) {
            f = 0.25;
        } else {
            f += x * 0.1;
            if (f > 2) f = 2;
            if (f < 0.2) f = 0.2;
        }
        editor.canvas.setHeight(2732 * f);
        editor.canvas.setWidth(2048 * f);
        editor.canvas.setZoom(f);
    }


    const makeId = (length) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    const addRect = () => {
        // Initiate a Rect instance

        const stationID = makeId();

        const rectangle = new fabric.Rect({
            width: 200,
            height: 100,
            stroke: primaryColor,
            fill: 'transparent',
            id: stationID,
            name: 'rettangolo',
            centeredScaling: true,
            top: 200,
            left: 100
        });

        const t = new fabric.Text('2', {
            fontFamily: 'Calibri',
            fontSize: 25,
            fill: secondaryColor,
            textAlign: 'center',
            originX: 'center',
            originY: 'center',
            left: 200,
            top: 250
        });


        const g = new fabric.Group([rectangle, t], {
            box: {id: 0},
            hasControls: true,
            rectId: stationID
        });

        editor?.canvas.add(g);
        editor.canvas.renderAll();
    }

    const handleElement = (obj) => {
        console.log('obj is???', obj)
        if (obj.selected) {
            console.log('obj is???', obj.selected)
            setSelected(obj.selected[0])
            setSelectedValue(obj.selected[0]._objects[1].text)
        } else {
            setSelected(undefined)
        }
    }

    useEffect(() => {
        let render = false;
        if (selected) {
            editor.canvas.getActiveObjects().forEach((obj) => {
                const object = obj._objects[1];
                if (object.text !== selectedValue) {
                    object.set('text', selectedValue);
                    render = true;
                }
            });
            if (render) {
                editor.canvas.renderAll()
            }
        }
    }, [selectedValue])

    const deleteRect = () => {
        editor.canvas.getActiveObjects().forEach((obj) => {
            if (obj._objects[1].ship) {
                showNotification({
                    color: 'red',
                    icon: <FontAwesomeIcon icon={faCross}/>,
                    message: 'Svuota il box selezionato prima di cancellarlo.',
                })
            } else {
                editor.canvas.remove(obj)
            }
        });
        editor.canvas.discardActiveObject().renderAll()
        setSelected(undefined)
    }
    const updateMap = () => {
        const items = [];
        editor.canvas.getObjects().map(o => {
            if (o._objects.length === 2) {
                const json = o.toJSON();
                items.push({
                    name: o._objects[1].text,
                    content: json
                })
            }
        });
        setIsSaving(true)
        MapService.updateMap({slots: items})
            .then(r => {
                //  loadMap(r)
                showNotification({
                    color: 'teal',
                    icon: <FontAwesomeIcon icon={faCheck}/>,
                    message: 'Mappa aggiornata',
                })
            })
            .catch(genericError)
            .finally(() => setIsSaving(false))
    }


    return <>
        <Button.Group>
            <Button onClick={() => zoomFoo(-1)} variant={'outline'}>
                <FontAwesomeIcon icon={faMagnifyingGlassMinus}/>
            </Button>

            <Button onClick={() => zoomFoo(0)} variant={'outline'}>
                Reset zoom
            </Button>

            <Button onClick={() => zoomFoo(1)} variant={'outline'}>
                <FontAwesomeIcon icon={faMagnifyingGlassPlus}/>
            </Button>

            <Button onClick={() => addRect(0)}>
                Aggiungi box
            </Button>

            <Button disabled={!selected} color={'red'} onClick={() => deleteRect()}>Elimina box</Button>

        </Button.Group>

        {isEditing &&
            <Button.Group style={{position: 'fixed', zIndex: 9, bottom: 100, right: 16}} orientation={'vertical'}>
                <Button variant={'filled'}
                        disabled={isSaving}
                        loading={isSaving}
                        leftIcon={<FontAwesomeIcon icon={faSave}/>}
                        onClick={() => updateMap()}>
                    Salva
                </Button>

            </Button.Group>
        }


        <Drawer opened={selected}
                position={'right'}
                padding={16}
                lockScroll={false}
                onClose={() => setSelected(undefined)}>
            <TextInput label={'Modifica numero'} mt={10} mb={20}
                       value={selectedValue}
                       onChange={(e) => setSelectedValue(e.target.value)}/>
        </Drawer>


        <Card
            withBorder
            shadow={'sm'}
            style={{
                maxWidth: '100%',
                position: "relative",
                borderRadius: 6,
                padding: 16,
                marginTop: 8
            }}>
            <LoadingOverlay visible={isLoading}/>
            <ActionIcon
                onClick={() => {
                    console.log('click left')
                    cardRef.current.scrollLeft -= 20;
                }}
                style={{
                    position: 'absolute',
                    top: '50%',
                    marginTop: -25,
                    zIndex: 999
                }}>
                <FontAwesomeIcon icon={faChevronLeft}/>
            </ActionIcon>
            <ActionIcon
                onClick={() => {
                    console.log('click right')
                    cardRef.current.scrollLeft += 20;
                }}
                style={{
                    position: 'absolute',
                    top: '50%',
                    marginTop: -25,
                    right: 0,
                    zIndex: 999
                }}>
                <FontAwesomeIcon icon={faChevronRight}/>
            </ActionIcon>
            <ActionIcon
                onClick={() => {
                    cardRef.current.scrollTop -= 20;
                }}
                style={{
                    position: 'absolute',
                    left: '50%',
                    marginLeft: -25,
                    top: 0,
                    zIndex: 999
                }}>
                <FontAwesomeIcon icon={faChevronUp}/>
            </ActionIcon>
            <ActionIcon
                onClick={() => {
                    cardRef.current.scrollTop += 20;
                }}
                style={{
                    position: 'absolute',
                    left: '50%',
                    marginLeft: -25,
                    bottom: 0,
                    zIndex: 999
                }}>
                <FontAwesomeIcon icon={faChevronDown}/>
            </ActionIcon>
            <div ref={cardRef} style={{
                overflow: 'hidden',
                height: 600,
            }}>
                <FabricJSCanvas
                    onReady={onReady}/>
            </div>
        </Card>


    </>
}
export default MapEdit;