import {useNavigate, useParams} from "react-router-dom";
import {
    Breadcrumbs,
    Title,
    Divider,
    Tooltip,
    Button,
    Flex,
    Image,
    LoadingOverlay,
    Stack,
    Text,
    Group
} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePlus} from "@fortawesome/pro-regular-svg-icons/faFilePlus";
import {faScrewdriverWrench} from "@fortawesome/pro-regular-svg-icons/faScrewdriverWrench";
import ModalShipDocumentComponent from "./components/ModalShipDocument.component";
import {faDownload} from "@fortawesome/pro-regular-svg-icons/faDownload";
import {downloadBlob} from "../../functions/downloadBlob";
import {ShipService} from "../../services/shipService";
import {genericError} from "../../functions/genericError";
import {faTrash} from "@fortawesome/pro-regular-svg-icons/faTrash";
import {openConfirmModal} from "@mantine/modals";
import {showNotification} from "@mantine/notifications";
import {DataTable} from "mantine-datatable";
import {useViewportSize} from "@mantine/hooks";
import {DocumentService} from "../../services/documentService";

const initialState = {
    type: '',
    authority: '',
    number: '',
    date_of_issue: '',
    expiration_date: '',
    unit_type: '',
    owner: '',
    date_of_entry: '',
    date_of_exit: '',
}

const ShipDocuments = () => {

    let {shipId} = useParams();
    const navigate = useNavigate()
    const [isLoading, setIsLoading] = useState(false)
    const [isDownloading, setIsDownloading] = useState()
    const [ship, setShip] = useState()
    const [isModalDocumentVisible, setIsModalDocumentVisible] = useState()
    const [deletingDocumentId, setDeletingDocumentId] = useState()
    const {  width } = useViewportSize();

    useEffect(() => {
        if (!shipId) return;
        getShipById(shipId)
    }, [shipId])



    const getShipById = async (id) => {
        setIsLoading(true)
        ShipService.get(id).then(setShip).catch(genericError).finally(() => setIsLoading(false))
    }

    const items = [
        {title: 'Imbarcazioni', href: '/ships'},
        {title: ship?.name},
    ].map((item, index) => (
        <div key={index}
             className={item.href ? 'navigate-anchor' : ''}
             onClick={() => {
                 if (item.href) {
                     navigate(item.href)
                 }
             }}>
            {item.title}
        </div>
    ));


    const download = async (element) => {
        setIsDownloading(element.id);
        DocumentService.get(element.ship_id, element.id)
            .then((r) => window.open(r, '_blank'))
            .catch(genericError)
            .finally(() => setIsDownloading(undefined))
    }

    const confirmDeleteDocument = (document) => openConfirmModal({
        title: 'Conferma eliminazione',
        children: (
            <Text size="sm">
                Sei sicuro di voler eliminare il documento {document.number}?
            </Text>
        ),
        labels: {confirm: 'Conferma', cancel: 'Annulla'},
        zIndex: 138,
        confirmProps: {color: 'red'},
        onCancel: () => console.log('Azione annullata'),
        onConfirm: () => deleteDocumentFunction(document),
    });

    const deleteDocumentFunction = async (document) => {
        setDeletingDocumentId(document.id)
        DocumentService.delete(document.ship_id, document.id)
            .then((r) => {
                showNotification({
                    color: 'teal',
                    icon: <FontAwesomeIcon icon={faTrash}/>,
                    message: 'Documento eliminato',
                })
                setShip(r)
            })
            .catch(genericError)
            .finally(() => {
                setDeletingDocumentId(undefined)
            })
    }


    return <>
        <Breadcrumbs>{items}</Breadcrumbs>
        <Divider mt={8} mb={16}/>

        <Flex justify={'space-between'} align={'center'}>

                <Stack>
                    <Title order={1}>Documenti imbarcazione: {ship?.name}</Title>
                    <Title order={3}>Modello: {ship?.model}</Title>
                </Stack>

            <Tooltip label="Aggiungi imbarcazione" withinPortal>
                <Button onClick={() => setIsModalDocumentVisible(initialState)}>
                    <FontAwesomeIcon size={'xl'} icon={faFilePlus}/>
                </Button>
            </Tooltip>
        </Flex>

        <div style={{position: 'relative'}}>
            <LoadingOverlay visible={isLoading} overlayBlur={2}/>
            <DataTable
                minHeight={400}
                withBorder
                records={ship?.documents}
                columns={[
                    { accessor: 'type', title: 'Tipo' },
                    { accessor: 'number',  title: 'Numero doc' },
                    { accessor: 'owner',  title: 'Proprietario', width: 100 },

                    {
                        accessor: 'Azioni',
                        width: width <= 1200 ? 100 : 240,
                        render: (element) => <Button.Group orientation={width <= 1200 ?  'vertical' : 'horizontal'}>
                                <Tooltip label="Scarica documento" withinPortal>
                                    <Button variant="light"
                                            disabled={isDownloading === element.id}
                                            loading={isDownloading === element.id}
                                            onClick={() => download(element)}>
                                        <FontAwesomeIcon icon={faDownload}/>
                                    </Button>
                                </Tooltip>
                                <Tooltip label="Modifica documento" withinPortal>
                                    <Button variant="light"
                                            onClick={() => setIsModalDocumentVisible(element)}>
                                        <FontAwesomeIcon icon={faScrewdriverWrench}/>
                                    </Button>
                                </Tooltip>
                                <Tooltip label="Elimina documento" withinPortal>
                                    <Button variant="light"
                                            disabled={deletingDocumentId === element.id}
                                            loading={deletingDocumentId === element.id}
                                            onClick={() => confirmDeleteDocument(element)}>
                                        <FontAwesomeIcon icon={faTrash} color={'red'}/>
                                    </Button>
                                </Tooltip>
                            </Button.Group>
                    },
                ]}
                striped
                highlightOnHover
                recordsPerPage={30}
                loadingText="Caricamento in corso..."
                noRecordsText="Nessun risultato trovato"
                rowExpansion={{
                    collapseProps: {
                        transitionDuration: 500,
                        animateOpacity: false,
                        transitionTimingFunction: 'ease-out',
                    },
                    content: ({ record }) => (
                        <Stack p="xs" spacing={6}>
                            <Group spacing={6}>
                                <Text>Data rilascio documento:</Text>
                                <Text>
                                    {record.date_of_issue}
                                </Text>
                            </Group>
                            <Group spacing={6}>
                                <Text>Data scadenza documento:</Text>
                                <Text>
                                    {record.expiration_date}
                                </Text>
                            </Group>
                            <Group spacing={6}>
                                <Text>Data ingresso:</Text>
                                <Text>
                                    {record.date_of_entry}
                                </Text>
                            </Group>
                            <Group spacing={6}>
                                <Text>Data uscita:</Text>
                                <Text>
                                    {record.date_of_exit}
                                </Text>
                            </Group>
                        </Stack>
                    ),
                }}
            />
        </div>

        {ship && <ModalShipDocumentComponent
            document={isModalDocumentVisible}
            setDocument={setIsModalDocumentVisible}
            setShip={setShip}
            ship={ship}/>}
    </>
}

export default ShipDocuments;