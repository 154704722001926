import {Button, Group, Modal, NumberInput, Stack, TextInput} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from '@mantine/form';
import {useEffect, useState} from "react";
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";
import {showNotification} from "@mantine/notifications";
import {genericError} from "../../../functions/genericError";
import {WarehouseService} from "../../../services/warehouseService";


const ModalItemComponent = (props) => {

    const [isSaving, setIsSaving] = useState(false)

    const form = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        criteriaMode: "firstError",
        shouldFocusError: true
    });


    useEffect(() => {
        if (!props.item) return;

        form.setValues({
            name: props.item.name,
            quantity: props.item.quantity,
        })
        return () => {
            form.reset();
        }
    }, [props.item])




    const formOnSubmit = async (values) => {

       if(props.item.id){
            WarehouseService.update(values, props.item.id)
                .then((r) => {
                    showNotification({
                        color: 'teal',
                        icon: <FontAwesomeIcon icon={faCheck}/>,
                        message: 'Oggetto aggiornato',
                    })
                    props.updateItems('update', r)
                    props.setItem(undefined)
                })
                .catch(genericError)
                .finally(()=> setIsSaving(false))
        } else {
            WarehouseService.create(values)
                .then((r) => {
                    showNotification({
                        color: 'teal',
                        icon: <FontAwesomeIcon icon={faCheck}/>,
                        message: 'Oggetto salvato',
                    })
                    props.updateItems('create', r)
                    props.setItem(undefined)
                })
                .catch(genericError)
                .finally(()=> setIsSaving(false))
        }
    }


    return <Modal
        size={'90%'}
        opened={props.item}
        lockScroll={false}
        onClose={() => props.setItem(undefined)}
        title={props.item?.id ? `Modifica oggetto` : 'Crea nuovo oggetto'}
    >

        <form onSubmit={form.onSubmit(formOnSubmit)}>

            <Stack>
                <TextInput
                    label="Nome"
                    {...form.getInputProps('name')}
                />
                <TextInput
                    label="Marca"
                    {...form.getInputProps('brand')}
                />
                <NumberInput
                    label={'Quantità'}
                    {...form.getInputProps('quantity')}
                />
            </Stack>

            <Group position="right" mt="md">
                <Button type="submit"
                        loading={isSaving}
                        disabled={isSaving}>
                    Salva
                </Button>
            </Group>
        </form>

    </Modal>
}
export default ModalItemComponent;
