import {TextInput, Button, Group, PasswordInput, Anchor, Container, Paper, Title, Text} from '@mantine/core';
import {useForm} from '@mantine/form';
import {useState} from "react";
import {Auth} from "aws-amplify";
import {showNotification} from "@mantine/notifications";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCross} from "@fortawesome/pro-regular-svg-icons/faCross";
import {useNavigate} from "react-router-dom";

const Login = ({setUser, setAuthState, isRoute = false}) => {

    const navigate = useNavigate();

    const form = useForm({
        initialValues: {
            email: '',
            password: ''
        },

        validate: {
            email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Email non valida'),
            password: (value) => value.length >= 8 ? null : 'La lunghezza minima della password è di 8 caratteri'
        },
    });

    const [isLoading, setIsLoading] = useState(false)

    const onFormSubmit = (values) => {
        setIsLoading(true)
        const username = values.email;
        const password = values.password;
        Auth.signIn({
            username,
            password,
            validationData: []
        })
            .then((user) => {
                setUser(user)
                switch (user.challengeName) {
                    case 'SMS_MFA':
                    case 'SOFTWARE_TOKEN_MFA':
                        setAuthState('MFA')
                        break;
                    case 'NEW_PASSWORD_REQUIRED':
                        setAuthState('FORCE_CHANGE_PW')
                        break;
                    case 'MFA_SETUP':
                        setAuthState('MFA_SETUP')
                        break;
                    default:
                        setAuthState('SIGNED_IN')
                }
            })
            .catch(err => {
                switch (err.code) {
                    case 'UserNotConfirmedException':
                        setAuthState('CONFIRM_USER_REGISTRATION')
                        break;

                    case 'PasswordResetRequiredException':
                        setAuthState('ASK_RESET_PW')
                        break;

                    case 'NotAuthorizedException':
                    case 'UserNotFoundException':
                        showNotification({
                            color: 'red',
                            icon: <FontAwesomeIcon icon={faCross}/>,
                            title: 'Errore',
                            message: `Credenziali errate`,
                        })
                        break;

                    default:
                        showNotification({
                            color: 'red',
                            icon: <FontAwesomeIcon icon={faCross}/>,
                            title: 'Errore',
                            message: err.message
                        })
                }
            })
            .finally(() => {
                setIsLoading(false)
            });
    }

    return <Container size={420} my={40}>
        <Title
            align="center"
            sx={(theme) => ({fontFamily: `Greycliff CF, ${theme.fontFamily}`, fontWeight: 900})}
        >
            Bentornato
        </Title>
        <Text color="dimmed" size="sm" align="center" mt={5}>
            Sezione riservata Ar-Nav Marina
        </Text>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md" className={'login-form'}>
            <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
                <TextInput label="Email" placeholder="you@gmail.com" required   {...form.getInputProps('email')}/>
                <PasswordInput label="Password" placeholder="Your password" required
                               mt="md"   {...form.getInputProps('password')}/>
                <Group position="right" mt="lg">
                    <Anchor onClick={() => {
                        if (isRoute) {
                            navigate('/forgot_password')
                        } else {
                            setAuthState('ASK_RESET_PW')
                        }
                    }} size="sm">
                        Password dimenticata?
                    </Anchor>
                </Group>
                <Button fullWidth mt="xl" type={'submit'} loading={isLoading} disabled={isLoading}>
                    Entra
                </Button>
            </form>
        </Paper>
    </Container>
}
export default Login