import { Card } from '@mantine/core';
import {Helmet} from "react-helmet";


const Dashboard = () => {
    return <>

        <Card withBorder radius="md" p="md" mt={24}>
            <Card.Section>
                <a className="weatherwidget-io" href="https://forecast7.com/it/44d079d96/ameglia/"
                   data-label_1="AMEGLIA"
                   data-label_2="METEO" data-font="Ubuntu" data-theme="pure">AMEGLIA METEO</a>
            </Card.Section>
        </Card>

        <Card withBorder radius="md" p="md" mt={24}>
            <Card.Section>
                <iframe width="100%" height="450" style={{borderRadius: 10}}
                        src="https://embed.windy.com/embed2.html?lat=44.017&lon=9.979&detailLat=41.890&detailLon=12.513&width=650&height=450&zoom=11&level=surface&overlay=wind&product=ecmwf&menu=&message=true&marker=&calendar=12&pressure=true&type=map&location=coordinates&detail=&metricWind=default&metricTemp=default&radarRange=-1"
                />
            </Card.Section>
        </Card>


        {/*
        <Divider mt={60}/>
        <Title>demo</Title>
        <StatsControls/>
        <Card
            withBorder
            radius="md"
            p="xl"
            sx={(theme) => ({
                backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[7] : theme.white,
            })}
        >
            <Text size="xs" transform="uppercase" weight={700} color="dimmed">
                Monthly goal
            </Text>
            <Text size="lg" weight={500}>
                $5.431 / $10.000
            </Text>
            <Progress value={54.31} mt="md" size="lg" radius="xl"/>
        </Card>
        */}

        <Helmet>
            <script>
                {`!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0];if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src='https://weatherwidget.io/js/widget.min.js';fjs.parentNode.insertBefore(js,fjs);}}(document,'script','weatherwidget-io-js');`}
            </script>
        </Helmet>
    </>
}
export default Dashboard;