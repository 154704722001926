import React, {useEffect, useState} from "react";
import {Button, Flex,  LoadingOverlay, Text, Title, Tooltip} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/pro-regular-svg-icons/faTrash";
import ModalItemComponent from "./components/ModalItem.component";
import {showNotification} from "@mantine/notifications";
import {openConfirmModal} from "@mantine/modals";
import {genericError} from "../../functions/genericError";
import {DataTable} from "mantine-datatable";

import {useViewportSize} from "@mantine/hooks";
import {WarehouseService} from "../../services/warehouseService";
import {faWarehouse} from "@fortawesome/pro-regular-svg-icons/faWarehouse";
import {faWarehouseAlt} from "@fortawesome/pro-regular-svg-icons/faWarehouseAlt";


const initialState = {}
const Warehouse = () => {

    const [isLoading, setIsLoading] = useState(false)
    const [deletingItemId, setDeletingItemId] = useState()
    const {width} = useViewportSize();
    const [items, setItems] = useState([])
    const [item, setItem] = useState();

    useEffect(() => {
        fetchItems();
    }, [])

    const updateItems = (method, element) => {
        let clone = [...items];
        if (method !== 'create') {
            clone = clone.filter(i => i.id !== element.id);
        }
        if (method === 'delete') {
            setItems([...clone])
        } else {
            setItems([element, ...clone])
        }
    }


    async function fetchItems(silently = false) {
        if (!silently) {
            setIsLoading(true)
        }
        WarehouseService.list().then(setItems).catch(genericError).finally(() => setIsLoading(false))
    }

    const confirmDeleteItem = (event) => openConfirmModal({
        title: 'Conferma eliminazione',
        children: (
            <Text size="sm">
                Sei sicuro di voler eliminare questo oggetto?
            </Text>
        ),
        labels: {confirm: 'Conferma', cancel: 'Annulla'},
        zIndex: 138,
        confirmProps: {color: 'red'},
        onCancel: () => console.log('Azione annullata'),
        onConfirm: () => deleteItemFunction(event),
    });

    const deleteItemFunction = async (item) => {
        setDeletingItemId(document.id)
        WarehouseService.delete(item.id)
            .then((r) => {
                showNotification({
                    color: 'teal',
                    icon: <FontAwesomeIcon icon={faTrash}/>,
                    message: 'Oggetto eliminato',
                })
                setItems([...items.filter(e => e.id !== item.id)])
            })
            .catch(genericError)
            .finally(() => {
                setDeletingItemId(undefined)
            })
    }

    return <>
        <Flex justify={'space-between'} align={'center'}>
            <Title order={1} className={'no-print'}> <FontAwesomeIcon icon={faWarehouse} style={{marginRight: 15}}/> Scheda
                magazzino</Title>
            <Button.Group>
                <Tooltip label="Aggiungi un nuovo evento" withinPortal>
                    <Button onClick={() => setItem(initialState)} disabled={isLoading}>
                        <FontAwesomeIcon size={'xl'} icon={faWarehouseAlt}/>
                    </Button>
                </Tooltip>
                <Button color='teal' className={'no-print'} onClick={() => window.print()}  disabled={isLoading}>
                    Download
                </Button>
            </Button.Group>
        </Flex>

        <ModalItemComponent setItem={setItem} item={item} updateItems={updateItems}/>
        <div style={{position: 'relative'}}>
            <LoadingOverlay visible={isLoading} overlayBlur={2}/>
            <DataTable
                mt={40}
                minHeight={400}
                withBorder
                records={items}
                columns={[
                    {accessor: 'name', title: 'Nome'},
                    {accessor: 'brand', title: 'Marca'},
                    {accessor: 'quantity', title: 'Quantità', width: 100},
                    {
                        accessor: 'Azioni',
                        width: width <= 1200 ? 100 : 240,
                        render: (element) => <Button.Group orientation={width <= 1200 ? 'vertical' : 'horizontal'}>
                            <Tooltip label="Elimina documento" withinPortal>
                                <Button variant="light"
                                        disabled={deletingItemId === element.id}
                                        loading={deletingItemId === element.id}
                                        onClick={() => confirmDeleteItem(element)}
                                >
                                    <FontAwesomeIcon icon={faTrash} color={'red'}/>
                                </Button>
                            </Tooltip>
                        </Button.Group>
                    },
                ]}
                onRowClick={(e) => setItem(e)}
                striped
                highlightOnHover
                recordsPerPage={30}
                loadingText="Caricamento in corso..."
            />
        </div>

    </>
}
export default Warehouse;