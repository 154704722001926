import React, {useEffect, useState} from "react";
import {Button, Card, Flex, LoadingOverlay, Text, Title, Tooltip} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/pro-regular-svg-icons/faCalendarAlt";
import {getMonthName} from "../../functions/getMonthName";
import {faChevronLeft} from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import {faChevronRight} from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import {faTrash} from "@fortawesome/pro-regular-svg-icons/faTrash";
import {faCalendarPlus} from "@fortawesome/pro-regular-svg-icons/faCalendarPlus";
import ModalEventComponent from "./components/ModalEvent.component";
import {showNotification} from "@mantine/notifications";
import {openConfirmModal} from "@mantine/modals";
import format from 'date-fns/format';
import {genericError} from "../../functions/genericError";
import {EventService} from "../../services/eventService";
import {DataTable} from "mantine-datatable";
import {faWrench} from "@fortawesome/pro-regular-svg-icons/faWrench";
import {getLongDay} from "../../functions/getLongDay";


const initialState = {}
const Events = () => {

    const [date, setDate] = useState(new Date())
    const [isLoading, setIsLoading] = useState(false)

    const [deletingEventId, setDeletingEventId] = useState()
    const [events, setEvents] = useState([])
    const [event, setEvent] = useState();

    useEffect(() => {
        fetchEvents();
    }, [date])

    const updateEvents = (method, element) => {
        fetchEvents()
    }

    const daysInMonth = (date) => {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }

    async function fetchEvents(silently = false) {
        if (!silently) {
            setIsLoading(true)
        }
        EventService.listByDate(date).then((r) => {
            console.log(r)
            const days = daysInMonth(date)
            const tempEvents = [];
            for (let k = 1; k <= days; k++) {
                const rows = [];
                r.filter(e => parseInt(format(new Date(e.event_date), 'd')) === k).forEach(e => {
                    rows.push(e)
                });
                tempEvents.push({
                    day: k,
                    longDay: getLongDay(date, k),
                    //ship: rows.map(e => <div key={e.id}>{e.ship.name}</div>),
                    alaggio: rows.map((e) => <div key={e.id}>
                        {e.action === 'alaggio' ? <>
                            <Card style={{padding: '5px 15px', marginBottom: 5}} withBorder>
                                <Text weight={'bold'} onClick={() => setEvent(e)}>{e.ship.name}</Text>
                                <Text color={'dimmed'} size={'xs'}>{e.notes}</Text>
                            </Card>
                        </> : ''}
                    </div>),
                    varo: rows.map(e => <div key={e.id}>{e.action === 'varo' ? <>
                            <Card style={{padding: '5px 15px', marginBottom: 5}} withBorder>
                                <Text weight={'bold'}  onClick={() => setEvent(e)}>{e.ship.name}</Text>
                                <Text color={'dimmed'} size={'xs'}>{e.notes}</Text>
                            </Card>
                        </>
                        : ''}
                    </div>),
                    generico: rows.map(e => <div key={e.id}>{e.action === 'generico' ? <>
                            <Card style={{padding: '5px 15px', marginBottom: 5}} withBorder>
                                <Text weight={'bold'} onClick={() => setEvent(e)}>{e.ship.name}</Text>
                                <Text color={'dimmed'} size={'xs'}>{e.notes}</Text>
                            </Card>
                        </>
                        : ''}
                    </div>),
                    events: rows
                })
            }
            console.log('temp events', tempEvents)
            setEvents(tempEvents)
        }).catch(genericError).finally(() => setIsLoading(false))
    }

    const confirmDeleteEvent = (event) => openConfirmModal({
        title: 'Conferma eliminazione',
        children: (
            <Text size="sm">
                Sei sicuro di voler eliminare questo evento {event.action}?
            </Text>
        ),
        labels: {confirm: 'Conferma', cancel: 'Annulla'},
        zIndex: 138,
        confirmProps: {color: 'red'},
        onCancel: () => console.log('Azione annullata'),
        onConfirm: () => deleteEventFunction(event),
    });

    const deleteEventFunction = async (event) => {
        setDeletingEventId(document.id)
        EventService.delete(event.id)
            .then((r) => {
                showNotification({
                    color: 'teal',
                    icon: <FontAwesomeIcon icon={faTrash}/>,
                    message: 'Evento eliminato',
                })
                setEvents([...events.filter(e => e.id !== event.id)])
            })
            .catch(genericError)
            .finally(() => {
                fetchEvents();
                setDeletingEventId(undefined)
            })
    }

    return <div className={'print-50-perc'}>
        <Flex justify={'space-between'} align={'center'} className={'no-print'}>
            <Title order={1} className={'no-print'}> <FontAwesomeIcon icon={faCalendarAlt}
                                                                      style={{marginRight: 15}}/> Scheda
                eventi</Title>
            <Button.Group>
                <Tooltip label="Aggiungi un nuovo evento" withinPortal>
                    <Button onClick={() => setEvent(initialState)} disabled={isLoading}>
                        <FontAwesomeIcon size={'xl'} icon={faCalendarPlus}/>
                    </Button>
                </Tooltip>
                <Button color='teal' className={'no-print'} onClick={() => window.print()} disabled={isLoading}>
                    Download
                </Button>
            </Button.Group>
        </Flex>

        <Flex align={'center'} justify={'space-around'} mt={50} className={'print-mt-60'}>
            <Button onClick={() => setDate(new Date(date.setMonth(date.getMonth() - 1)))}>
                <FontAwesomeIcon
                    icon={faChevronLeft}/></Button>
            <Title order={3}>Mese di {getMonthName(date.getMonth())} {date.getFullYear()}</Title>
            <Button onClick={() => setDate(new Date(date.setMonth(date.getMonth() + 1)))}>
                <FontAwesomeIcon
                    icon={faChevronRight}/></Button>
        </Flex>


        <div style={{position: 'relative'}}>
            <LoadingOverlay visible={isLoading} overlayBlur={2}/>
            <DataTable
                className={'print-table'}
                mt={40}
                minHeight={400}
                withBorder
                records={events}
                columns={[
                    {accessor: 'day', title: 'Data', width: 100},
                    {accessor: 'longDay', title: 'Giorno', width: 250},
                    {accessor: 'alaggio', title: 'Alaggio'},
                    {accessor: 'varo', title: 'Varo'},
                    {accessor: 'generico', title: 'Generico'}
                ]}
                striped
                highlightOnHover
                recordsPerPage={30}
                loadingText="Caricamento in corso..."
            />
        </div>

        <ModalEventComponent setEvent={setEvent} event={event} updateEvents={updateEvents}/>
    </div>
}
export default Events;