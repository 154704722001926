import {Button, Flex, LoadingOverlay, Modal, TextInput} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useRef, useState} from "react";
import {useDebouncedValue, useFocusTrap, useMergedRef} from "@mantine/hooks";
import {faSearch} from "@fortawesome/pro-regular-svg-icons/faSearch";
import {ShipOwnerServicee} from "../../../services/shipOwnerService";
import sortBy from "lodash/sortBy";
import {DataTable} from "mantine-datatable";
import {ShipService} from "../../../services/shipService";
import {genericError} from "../../../functions/genericError";


const ModalAddClientComponent = (props) => {

    const [isSaving, setIsSaving] = useState(false)

    const PAGE_SIZE = 10;
    const [initialClients, setInitialClients] = useState([])
    const [clients, setClients] = useState([])
    const [size, setSize] = useState(0)

    const [isLoading, setIsLoading] = useState(false)
    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);

    const [sortStatus, setSortStatus] = useState({columnAccessor: 'name', direction: 'asc'});
    const [page, setPage] = useState(1)

    const [selectedRecords, setSelectedRecords] = useState([]);


    const searchRef = useRef();
    const focusTrapRef = useFocusTrap(true);
    const mergedRef = useMergedRef(searchRef, focusTrapRef)


    const reset = () => {
        setSelectedRecords([])
        setClients(initialClients)
        setPage(1)
        setQuery('')
    }

    useEffect(() => {
        if(props.ship) {
            fetchClients(true);
        }
        return () => {
            reset();
        }
    }, [props])

    useEffect(() => {
        if (initialClients.length === 0) {
            return;
        }
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setClients(initialClients.slice(from, to));
        setSize(initialClients.length)
    }, [page, initialClients]);


    useEffect(() => {
        if (debouncedQuery === '') {
            reset();
            return;
        }
        setClients(
            initialClients.filter((client) => {
                if (
                    (client.name && client.name.toLowerCase().includes(debouncedQuery.trim().toLowerCase())) ||
                    (client.surname && client.surname.toLowerCase().includes(debouncedQuery.trim().toLowerCase())) ||
                    (client.given_name && client.given_name.toLowerCase().includes(debouncedQuery.trim().toLowerCase())) ||
                    (client.email && client.email.toLowerCase().includes(debouncedQuery.trim().toLowerCase())) ||
                    (client.fiscal_code && client.fiscal_code.toLowerCase().includes(debouncedQuery.trim().toLowerCase()))
                ) {
                    return true;
                }
                return false;
            })
        );
    }, [debouncedQuery]);

    useEffect(() => {
        const data = sortBy(clients, sortStatus.columnAccessor);
        setClients(sortStatus.direction === 'desc' ? data.reverse() : data);
    }, [sortStatus]);

    const associateClientsWithShip = () => {
        setIsSaving(true)
        ShipService.associateClients(props.ship.id, {shipOwners: selectedRecords})
            .then((r) => {
                props.updateShips('update', r)
                props.setShip(undefined)
            })
            .catch(genericError)
            .finally(() => setIsSaving(false))
    }


    async function fetchClients(silently = false) {
        if (!silently) {
            setIsLoading(true)
        }
        ShipOwnerServicee.list().then((r) => {
            setClients(r)
            setInitialClients(r)
            setSize(r.length)
        }).finally(() => setIsLoading(false))
    }


    return <Modal
        size={'90%'}
        lockScroll={false}
        opened={props.ship}
        onClose={() => props.setShip(undefined)}
        title={`Aggiungi armatore a ${props.ship?.name}`}
    >

        <div style={{marginTop: 24, marginBottom: 24}}>
            <TextInput
                ref={mergedRef}
                onChange={e => {
                    setQuery(e.target.value)
                    if (e.target.value === '') {
                        reset();
                    }
                }}
                rightSectionProps={<FontAwesomeIcon icon={faSearch}/>}
                placeholder="Paolo Rossi"
                label="Cerca armatore per nome o cognome o ragione sociale, oppure codice fiscale"
            />
        </div>

        <div style={{position: 'relative'}} className={'selectableTable'}>
            <LoadingOverlay visible={isLoading} overlayBlur={2}/>
            <DataTable
                isRecordSelectable={(record) => (props.ship && props.ship.ship_owners) ? !props.ship.ship_owners.some(sr => sr.id === record.id) : true}
                selectedRecords={selectedRecords}
                onSelectedRecordsChange={setSelectedRecords}
                minHeight={400}
                withBorder
                sortStatus={sortStatus}
                onSortStatusChange={setSortStatus}
                records={clients}
                columns={[
                    {
                        accessor: 'identificativo', sortable: true,
                        width: '33%',
                        render: (element) => element.given_name ?? element.surname + ' ' + element.name,
                    },
                    {accessor: 'fiscal_code',
                        width: '33%',
                        sortable: true, title: 'Codice fiscale'},
                    {accessor: 'email', width: '34%', sortable: true}
                ]}
                totalRecords={size}
                striped
                highlightOnHover
                recordsPerPage={PAGE_SIZE}
                //idAccessor={'id'}
                page={page}
                onPageChange={(p) => setPage(p)}
                loadingText="Caricamento in corso..."
                noRecordsText="Nessun risultato trovato"
                // uncomment the next line to use a custom pagination text
                // paginationText={({ from, to, totalRecords }) => `Records ${from} - ${to} of ${totalRecords}`}
                // uncomment the next line to use a custom pagination color (see https://mantine.dev/theming/colors/)
                // paginationColor="grape"
                // uncomment the next line to use a custom pagination size
                // paginationSize="md"
            />
        </div>
        <Flex justify={'space-between'} mt={15}>
            <Button variant={'default'} onClick={() => props.setShip(undefined)}>Annulla</Button>
            <Button disabled={selectedRecords.length === 0 || isSaving}
                    loading={isSaving}
                    onClick={() => associateClientsWithShip()}>Associa armator*</Button>
        </Flex>
    </Modal>
}
export default ModalAddClientComponent;
