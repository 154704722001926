import {useSearchParams} from "react-router-dom";
import {Button, Flex, Loader} from "@mantine/core";
import React, {useEffect, useState} from "react";
import {EstimateService} from "../../services/estimateService";
import {genericError} from "../../functions/genericError";

const EstimateForm = () => {
    const [searchParams] = useSearchParams();
    const [isLoading, setIsLoading] = useState(true)
    const [isDownloading, setIsDownloading] = useState(false)
    const [isDownloading2, setIsDownloading2] = useState(false)
    const [url, setUrl] = useState('')

    const retrieveEstimate = async () => {
        setIsLoading(true)
        EstimateService.getPreview(searchParams.get('id'))
            .then(ep => {
                setUrl(`${process.env.REACT_APP_URL_2}/prevEstimate?id=${ep.estimate_id}&email=${ep.email}&token=${ep.token}`)
            })
            .catch(genericError)
            .finally(() => setIsLoading(false))

    }

    const downloadPdf = async () => {
        setIsDownloading(true)
        EstimateService.downloadPdf(searchParams.get('id'))
            .then(r => {
                window.open(URL.createObjectURL(r));
            })
            .catch(genericError)
            .finally(() => setIsDownloading(false))

    }

    const downloadPdfNotes = async () => {
        setIsDownloading2(true)
        EstimateService.downloadPdfNotes()
            .then(r => {
                window.open(r,'_blank');
            })
            .catch(genericError)
            .finally(() => setIsDownloading2(false))

    }



    useEffect(() => {
        if (searchParams.get('id') && searchParams.get('id') !== '') {
            retrieveEstimate();
        }
    }, [searchParams])


    return isLoading ?
        <Flex justify={'center'} align={'center'} style={{height: '100vh'}}>
            <Loader size={'xl'} variant={'bars'} style={{margin: 'auto'}}/>
        </Flex> : <div style={{minHeight: '100vh', display: 'flex'}}>
            <iframe style={{flex: 1}} src={url}/>

            <Flex direction={'column'} gap={16} ml={16}>
                <Button onClick={() => downloadPdf()} disabled={isDownloading} loading={isDownloading}>PDF preventivo</Button>
                <Button onClick={() => downloadPdfNotes()} disabled={isDownloading2} loading={isDownloading2}>PDF condizioni</Button>
            </Flex>

        </div>
}
export default EstimateForm