import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus} from "@fortawesome/pro-regular-svg-icons/faUserPlus";
import {faScrewdriverWrench} from "@fortawesome/pro-regular-svg-icons/faScrewdriverWrench";
import {faUsers} from "@fortawesome/pro-regular-svg-icons/faUsers";
import {useNavigate} from "react-router-dom";
import {Text,  Title, Button, TextInput, Flex, LoadingOverlay, Tooltip} from "@mantine/core";
import { useDebouncedValue, useFocusTrap, useMergedRef} from "@mantine/hooks";
import {faSearch} from "@fortawesome/pro-regular-svg-icons/faSearch";
import {openConfirmModal} from "@mantine/modals";
import {faFiles} from "@fortawesome/pro-regular-svg-icons/faFiles";
import {faTrash} from "@fortawesome/pro-regular-svg-icons/faTrash";
import {DataTable} from "mantine-datatable";
import sortBy from 'lodash/sortBy';
import {genericError} from "../../functions/genericError";
import {showNotification} from "@mantine/notifications";
import {ShipOwnerServicee} from "../../services/shipOwnerService";
import ModalClientComponent from "./components/ModalClient.component";

const initialState = {
    name: '',
    surname: '',
    given_name: '',
    fiscal_code: '',
    vat_number: '',
    email: '',
    code: '',
    phone: '',
    phone_2: '',
    fax: '',
}

const Clients = () => {

    const PAGE_SIZE = 10;
    const navigate = useNavigate();
    const [initialClients, setInitialClients] = useState([])
    const [clients, setClients] = useState([])
    const [size, setSize] = useState(0)

    const [isLoading, setIsLoading] = useState(false)

    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);

    const [sortStatus, setSortStatus] = useState({ columnAccessor: 'name', direction: 'asc' });

    const [showModalClient, setShowModalClient] = useState(undefined)
    const searchRef = useRef();
    const focusTrapRef = useFocusTrap(true);
    const mergedRef = useMergedRef(searchRef, focusTrapRef)

    const [deletingClientId, setDeletingClientId] = useState()

    const [page, setPage] = useState(1)

    const reset = () => {
        setClients(initialClients)
        searchRef.current.value = '';
        setPage(1)
        setQuery('')
    }


    async function fetchClients(silently = false) {
        if (!silently) {
            setIsLoading(true)
        }
        ShipOwnerServicee.list().then((r) => {
            setClients(r)
            setInitialClients(r)
            setSize(r.length)
        }).finally(() => setIsLoading(false))

    }

    const updateClients = (method, element) => {
        let clone = [...clients];
        let clone2 = [...initialClients];
        if(method !== 'create'){
            clone = clone.filter(i => i.id !== element.id);
            clone2 = clone2.filter(i => i.id !== element.id);
        }
        if(method === 'delete'){
            setClients([...clone])
            setInitialClients([...clone2])
        } else {
            setClients([element, ...clone])
            setInitialClients([element, ...clone2])
        }
    }


    useEffect(() => {
        if(initialClients.length === 0){
            return;
        }
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setClients(initialClients.slice(from, to));
        setSize(initialClients.length)
    }, [page, initialClients]);

    useEffect(() => {
        fetchClients();
    }, [])

    useEffect(() => {
        if(debouncedQuery === ''){
            reset();
            return;
        }
        setClients(
            initialClients.filter((client) => {
                if (
                    (client.name && client.name.toLowerCase().includes(debouncedQuery.trim().toLowerCase())) ||
                    (client.surname && client.surname.toLowerCase().includes(debouncedQuery.trim().toLowerCase())) ||
                    (client.given_name && client.given_name.toLowerCase().includes(debouncedQuery.trim().toLowerCase())) ||
                    (client.email && client.email.toLowerCase().includes(debouncedQuery.trim().toLowerCase())) ||
                    (client.fiscal_code && client.fiscal_code.toLowerCase().includes(debouncedQuery.trim().toLowerCase()))
                ) {
                    return true;
                }
                return false;
            })
        );
    }, [debouncedQuery]);

    useEffect(() => {
        const data = sortBy(clients, sortStatus.columnAccessor);
        setClients(sortStatus.direction === 'desc' ? data.reverse() : data);
    }, [sortStatus]);


    const confirmDeleteClient = (client) => openConfirmModal({
        title: 'Conferma eliminazione',
        children: (
            <Text size="sm">
                Sei sicuro di voler eliminare l'armatore {client.given_name ?? client.surname + ' ' + client.name}?
            </Text>
        ),
        labels: {confirm: 'Conferma', cancel: 'Annulla'},
        zIndex: 138,
        confirmProps: {color: 'red'},
        onCancel: () => console.log('Azione annullata'),
        onConfirm: () => deleteClientFunction(client),
    });

    const deleteClientFunction = async (client) => {
        setDeletingClientId(client.id)
        ShipOwnerServicee.delete(client.id)
            .then(() => {
                showNotification({
                    color: 'teal',
                    icon: <FontAwesomeIcon icon={faTrash}/>,
                    message: 'Armatore eliminat*',
                })
                updateClients('delete', client)
            })
            .catch(genericError)
            .finally(() => {
                setDeletingClientId(undefined)
            })
    }



    return <>
        <Flex justify={'space-between'} align={'center'}>
            <Title order={1}><FontAwesomeIcon icon={faUsers} style={{marginRight: 15}}/> Scheda armatori</Title>

            <Tooltip label="Aggiungi imbarcazione" withinPortal>
                <Button onClick={() => setShowModalClient(initialState)} disabled={isLoading}>
                    <FontAwesomeIcon size={'xl'} icon={faUserPlus}/>
                </Button>
            </Tooltip>
        </Flex>

        <div style={{marginTop: 24, marginBottom: 24}}>
            <TextInput
                ref={mergedRef}
                onChange={e => {
                    setQuery(e.target.value)
                    if (e.target.value === '') {
                        reset();
                    }
                }}
                rightSectionProps={<FontAwesomeIcon icon={faSearch}/>}
                placeholder="Paolo Rossi"
                label="Cerca armatore per nome o cognome o ragione sociale, oppure codice fiscale"
            />
        </div>


        <div style={{position: 'relative'}}>
            <LoadingOverlay visible={isLoading} overlayBlur={2}/>
            <DataTable
                minHeight={400}
                withBorder
                sortStatus={sortStatus}
                onSortStatusChange={setSortStatus}
                records={clients}
                columns={[
                    { accessor: 'identificativo', sortable: true,
                        render: (element) => element.given_name ?? element.surname + ' ' + element.name,
                    },
                    { accessor: 'fiscal_code', sortable: true, title: 'Codice fiscale' },
                    { accessor: 'email', sortable: true },
                    {
                        accessor: 'Azioni',
                        render: (element) => <Button.Group>
                             <Tooltip label="Modifica armatore" withinPortal>
                                <Button variant="light"
                                        onClick={() => setShowModalClient(element)}>
                                    <FontAwesomeIcon icon={faScrewdriverWrench}/>
                                </Button>
                            </Tooltip>
                            <Tooltip label="Elimina armatore" withinPortal>
                                <Button variant="light" color={'red'}
                                        disabled={deletingClientId === element.id}
                                        loading={deletingClientId === element.id}
                                        onClick={() => confirmDeleteClient(element)}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </Button>
                            </Tooltip>
                        </Button.Group>
                    },
                ]}
                totalRecords={size}
                striped
                highlightOnHover
                recordsPerPage={PAGE_SIZE}
                //idAccessor={'id'}
                page={page}
                onPageChange={(p) => setPage(p)}
                loadingText="Caricamento in corso..."
                noRecordsText="Nessun risultato trovato"
                // uncomment the next line to use a custom pagination text
                // paginationText={({ from, to, totalRecords }) => `Records ${from} - ${to} of ${totalRecords}`}
                // uncomment the next line to use a custom pagination color (see https://mantine.dev/theming/colors/)
                // paginationColor="grape"
                // uncomment the next line to use a custom pagination size
                // paginationSize="md"
            />
        </div>

        <ModalClientComponent
            client={showModalClient}
            setClient={setShowModalClient}
            updateClients={updateClients}/>


    </>
}
export default Clients;