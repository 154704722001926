import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Amplify } from 'aws-amplify';
import awsExports from './aws-exports';
import './index.css'
import * as Sentry from "@sentry/react";
import {BrowserTracing} from "@sentry/react";

Amplify.configure(awsExports);

Sentry.init({
    dsn: "https://57705ebc0bbb454c8488fa5ff870becd@o4504892857516032.ingest.sentry.io/4504892883795968",
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
