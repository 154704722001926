import {useNavigate, useSearchParams} from "react-router-dom";
import {
    Button, Checkbox, Divider,
    Flex,
    LoadingOverlay,
    NumberInput,
    Select,
    Stack,
    Table,
    Text,
    Textarea,
    TextInput,
    Title
} from "@mantine/core";
import {useEffect, useState} from "react";
import {useForm} from "@mantine/form";
import {DatePicker} from "@mantine/dates";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTrash} from "@fortawesome/pro-regular-svg-icons/faTrash";
import {openConfirmModal} from "@mantine/modals";
import {showNotification} from "@mantine/notifications";
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";
import {faWarning} from "@fortawesome/pro-regular-svg-icons/faWarning";
import {EstimateService} from "../../services/estimateService";
import {genericError} from "../../functions/genericError";
import {format} from "date-fns";

const EstimateForm = () => {

    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [estimate, setEstimate] = useState()
    const [isSaving, setIsSaving] = useState(false)
    const [isSharing, setIsSharing] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const form = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        criteriaMode: "firstError",
        shouldFocusError: true,
        validate: {
            valid_until: (value) => (value.length < 2 ? 'Inserisci una data valida' : null)
        },
    });

    const retrieveEstimate = async () => {
        setIsLoading(true)
        EstimateService.get(searchParams.get('id'))
            .then(data => {
                setEstimate(data)
                data.valid_until = new Date(data.valid_until);
                data.created_at = new Date(data.created_at);
                form.setValues(data)
            })
            .catch((e) => {
                genericError(e)
                navigate('/estimates')
            })
            .finally(() => setIsLoading(false))
    }

    const formOnSubmit = async (values) => {
        if (!values) {
            values = form.values;
        }
        const c = structuredClone(values)
        c.valid_until = format(values.valid_until, 'yyyy-MM-dd');
        if (!c.is_total_hidden) {
            delete c.is_total_hidden;
        }
        setIsSaving(true);

        EstimateService.update(c, estimate.id)
            .then((r) => {
                showNotification({
                    color: 'teal',
                    icon: <FontAwesomeIcon icon={faCheck}/>,
                    message: 'Preventivo aggiornato',
                })
            })
            .catch(genericError)
            .finally(() => {
                setIsSaving(false)
            })
    }

    useEffect(() => {
        if (searchParams.get('id') && searchParams.get('id') !== '') {
            retrieveEstimate();
        }
    }, [searchParams])


    const deleteEstimateFunction = async () => {
        if (!estimate) return;
        setIsDeleting(true)
        EstimateService.delete(estimate.id)
            .then(() => {
                showNotification({
                    color: 'teal',
                    icon: <FontAwesomeIcon icon={faCheck}/>,
                    message: 'Preventivo eliminato',
                })
                navigate('/estimates')
            })
            .catch(genericError)
            .finally(() => setIsDeleting(false));
    }


    const openModal = (item) => openConfirmModal({
        title: 'Conferma eliminazione',
        children: (
            <Text size="sm">
                Sei sicuro di voler eliminare questa voce?
            </Text>
        ),
        labels: {confirm: 'Conferma', cancel: 'Annulla'},
        confirmProps: {color: 'red'},
        onCancel: () => console.log('Azione annullata'),
        onConfirm: () => removeItem(item),
    });

    const removeItem = (item) => {
        form.setValues({
            items: form.values.items.filter(i =>
                i.randomId ? i.randomId !== item.randomId : i.id !== item.id
            )
        })
    }

    const openModalDelete = () => openConfirmModal({
        title: 'Conferma eliminazione',
        children: (
            <Text size="sm">
                Sei sicuro di voler eliminare il preventivo {estimate.name}?
            </Text>
        ),
        labels: {confirm: 'Conferma', cancel: 'Annulla'},
        zIndex: 138,
        confirmProps: {color: 'red'},
        onCancel: () => console.log('Azione annullata'),
        onConfirm: () => deleteEstimateFunction(estimate),
    });


    const showPreview = async () => {
        const validate = form.validate();
        if (!validate.hasErrors && form.isDirty()) {
            await formOnSubmit();
        }
        navigate('/estimate/pre?id=' + estimate.id)
    }

    const share = async () => {

        console.log('estimate is', estimate)
        if (!estimate.ship_owner || !estimate.ship_owner.email) {
            showNotification({
                color: 'orange',
                icon: <FontAwesomeIcon icon={faWarning}/>,
                title: 'Impossibile inviare il messaggio',
                message: "Configurare l'email per questo armatore",
            })
            return;
        }
        setIsSharing(true)
        EstimateService
            .share(estimate.id, {
                email: estimate.ship_owner.email,
            })
            .then(() => {
                showNotification({
                    color: 'teal',
                    icon: <FontAwesomeIcon icon={faCheck}/>,
                    title: 'Email inviata'
                })
            })
            .catch(genericError)
            .finally(() => {
                setIsSharing(false)
            })
    }


    const rows = form.values?.items?.filter(d => !d._deleted).map((element, index) => (
        <tr key={index}>
            <td>
                <TextInput
                    required
                    placeholder="Verniciatura"
                    value={element.name}
                    {...form.getInputProps(`items.${index}.name`)}
                />
            </td>
            <td>
                <TextInput
                    placeholder="Descrizione"
                    value={element.description}
                    {...form.getInputProps(`items.${index}.description`)}
                />
            </td>
            <td>
                <NumberInput
                    placeholder="Quantità"
                    defaultValue={1}
                    value={element.quantity}
                    {...form.getInputProps(`items.${index}.quantity`)}
                />
            </td>
            <td>
                <NumberInput
                    placeholder="Costo unitario"
                    value={element.unit_cost}
                    {...form.getInputProps(`items.${index}.unit_cost`)}
                />
            </td>
            <td>
                <Select
                    placeholder="IVA"
                    defaultValue={22}
                    data={[
                        {value: 10, label: '10%'},
                        {value: 22, label: '22%'}
                    ]}
                    {...form.getInputProps(`items.${index}.vat`)}
                />
            </td>
            <td>
                <Button.Group>
                    <Button color={'red'} onClick={() => openModal(element)}>
                        <FontAwesomeIcon icon={faTrash}/>
                    </Button>
                </Button.Group>
            </td>
        </tr>
    ));

    return <>
        <Title>{!searchParams.get('id') || searchParams.get('id') === '' ? 'Crea nuovo preventivo' : 'Modifica preventivo ' + estimate?.name}</Title>
        <Text>Armatore: {estimate?.ship_owner?.name} {estimate?.ship_owner?.surname},
            CF: {estimate?.ship_owner?.fiscal_code}</Text>
        <Text>Barca: {estimate?.ship?.name}</Text>
        <Divider mt={12} mb={12}/>

        <form onSubmit={form.onSubmit(formOnSubmit)}>
            <div style={{position: 'relative'}}>
                <LoadingOverlay visible={isSaving || isLoading} overlayBlur={2}/>
                <Stack>
                    <TextInput
                        label="Numero preventivo"
                        {...form.getInputProps('number')}
                    />
                    <TextInput
                        label="Oggetto del preventivo"
                        {...form.getInputProps('name')}
                    />
                    <TextInput
                        label="Suggerimento (visibile a lato del nome imbarcazione)"
                        placeholder="Verniciatura barca Octavia"
                        {...form.getInputProps('description')}
                    />
                    <Select
                        label="Status"
                        required
                        {...form.getInputProps('status')}
                        data={[
                            {value: 'draft', label: 'Bozza'},
                            {value: 'sent', label: 'Inviato'},
                            {value: 'confirmed', label: 'Confermato'},
                        ]}
                    />
                    <DatePicker
                        locale={'it'}
                        style={{flex: 0.45}}
                        label="Valido fino al (15 giorni da oggi)"
                        inputFormat="YYYY-MM-DD"
                        required
                        withinPortal={true}
                        placeholder=""
                        {...form.getInputProps('valid_until')}
                    />
                    <Textarea
                        label="Note (non visibile)"
                        placeholder=""
                        {...form.getInputProps('notes')}
                    />
                    <DatePicker
                        locale={'it'}
                        label="Modifica data creazione preventivo"
                        inputFormat="YYYY-MM-DD"
                        withAsterisk
                        required
                        withinPortal={true}
                        placeholder=""
                        {...form.getInputProps('created_at')}
                    />
                    <Checkbox
                        {...form.getInputProps('is_total_hidden', {type: "checkbox"})}
                        label={'Nascondi totale'}/>

                </Stack>


                <Flex justify={'flex-end'} align={'center'} mt={24}>
                    <Button size={'sm'} variant={'light'}
                            onClick={() => {
                                form.setValues({
                                    items: [...form.values.items, {
                                        name: '',
                                        description: '',
                                        quantity: 1,
                                        unit_cost: 0,
                                        vat: '22'
                                    }]
                                })
                            }}>
                        Aggiungi voce
                    </Button>
                </Flex>

                <Table striped verticalSpacing={'sm'} highlightOnHover>
                    <thead>
                    <tr>
                        <th>Lavorazione</th>
                        <th>Descrizione</th>
                        <th>Quantità</th>
                        <th>Costo unitario</th>
                        <th>IVA</th>
                        <th>Azioni</th>
                    </tr>
                    </thead>
                    <tbody>{rows}</tbody>
                </Table>
            </div>


            <Flex justify={'space-between'} align={'center'} mt={24}>
                <Button
                    color="red"
                    variant={'filled'}
                    disabled={isDeleting || (!searchParams.get('id') || searchParams.get('id') === '')}
                    loading={isDeleting}
                    onClick={() => openModalDelete()}
                    type={'button'}>
                    Elimina preventivo
                </Button>

                <Button.Group>
                    <Button
                        variant={'filled'}
                        disabled={isSaving}
                        loading={isSaving}
                        type={'submit'}>
                        Salva bozza
                    </Button>
                    <Button
                        variant={'filled'}
                        disabled={!estimate || isSaving}
                        loading={isSaving}
                        color="cyan"
                        onClick={() => showPreview()}
                        type={'button'}>
                        Preview
                    </Button>
                    <Button
                        color="teal"
                        variant={'filled'}
                        disabled={isSharing}
                        loading={isSharing}
                        onClick={() => share()}
                        type={'button'}>
                        Invia al cliente
                    </Button>
                </Button.Group>
            </Flex>

        </form>
    </>
}
export default EstimateForm