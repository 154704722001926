import {AxiosInterceptor} from "./AxiosInterceptor";

export class ShipOwnerServicee {
    static list = async () => {
        const url = `${process.env.REACT_APP_URL}/shipOwners`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.get(url).then((res) => {
                    return res.data;
                });
            });
    }

    static create = async (data) => {
        const url = `${process.env.REACT_APP_URL}/shipOwner`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.post(url, data).then((res) => {
                    return res.data;
                });
            });
    }

    static update = async (data, id) => {
        const url = `${process.env.REACT_APP_URL}/shipOwner/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.put(url, data).then((res) => {
                    return res.data;
                });
            });
    }

    static delete = async (id) => {
        const url = `${process.env.REACT_APP_URL}/shipOwner/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.delete(url).then((res) => {
                    return res.data;
                });
            });
    }

}