import axios from "axios";
import {AxiosInterceptor} from "./AxiosInterceptor";

export default class AuthService {

    static refreshToken = async (refreshToken, deviceKey) => {
        return await axios.post(`${process.env.REACT_APP_URL}/user/refresh_token`, {
            refresh_token: refreshToken.getToken(),
            device_key: deviceKey,
            is_production: process.env.REACT_APP_MODE,
            domain: process.env.REACT_APP_ID,
        }).then(res => {
            return res.data;
        });
    };


}
