import {AxiosInterceptor} from "./AxiosInterceptor";

export class DocumentService {


    static get = async (shipId, id) => {
        const url = `${process.env.REACT_APP_URL}/ship/${shipId}/document/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.get(url).then((res) => {
                    return res.data;
                });
            });
    }

    static create = async (shipId, data) => {
        const formData = new FormData();
        Object.keys(data).forEach((key, index) => {
            formData.append(key, data[key]);
        })

        const url = `${process.env.REACT_APP_URL}/ship/${shipId}/document`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.post(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((res) => {
                    return res.data;
                });
            });
    }

    static update = async (shipId, data, id) => {
        const formData = new FormData();
        Object.keys(data).forEach((key, index) => {
            formData.append(key, data[key]);
        })

        const url = `${process.env.REACT_APP_URL}/ship/${shipId}/document/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.post(url, formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }).then((res) => {
                    return res.data;
                });
            });
    }

    static delete = async (shipId, id) => {
        const url = `${process.env.REACT_APP_URL}/ship/${shipId}/document/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.delete(url).then((res) => {
                    return res.data;
                });
            });
    }



}