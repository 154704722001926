import {showNotification} from "@mantine/notifications";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCross} from "@fortawesome/pro-regular-svg-icons/faCross";
import {List} from "@mantine/core";
import {isObject} from "lodash";



export function genericError(e){
    const obj = {
        color: 'red',
        icon: <FontAwesomeIcon icon={faCross}/>,
        title: e.response.status === 500 ? 'Errore del server' : 'Errore ' + e.response.status,
    }

    if(e.response && e.response.data && isObject(e.response.data)){
        const data = e.response.data;
        obj.message =  data.message ?? <List>
            {Object.entries(e.response.data).map((err, i) => <List.Item key={i}>{err[1]}</List.Item>)}
        </List>;
    }


    showNotification(obj)
}