import React from 'react';
import {forwardRef} from "react";
import {Group, Avatar, Text, UnstyledButton} from '@mantine/core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/pro-regular-svg-icons/faChevronRight";

const UserButton = forwardRef(
    ({user, icon, ...others}, ref) => (
        <UnstyledButton
            ref={ref}
            sx={(theme) => ({
                display: 'block',
                width: '100%',
                padding: theme.spacing.md,
                color: theme.colorScheme === 'dark' ? theme.colors.dark[0] : theme.black,

                '&:hover': {
                    backgroundColor:
                        theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.colors.gray[0],
                },
            })}
            {...others}
        >

            <Group>
                <Avatar radius="xl"/>

                <div style={{flex: 1}}>
                    <Text size="sm" weight={500}>
                        {`${user.attributes.name ?? ''} ${user.attributes.family_name ?? ''}`}
                    </Text>

                    <Text color="dimmed" size="xs">
                        {user.attributes.email}
                    </Text>
                </div>

                {icon || <FontAwesomeIcon icon={faChevronRight}/>}
            </Group>

        </UnstyledButton>
    )
);

export default UserButton;