import {useEffect, useRef, useState} from "react";
import {
    ActionIcon,
    Button, Card, Drawer, LoadingOverlay, Select, Text,
} from '@mantine/core';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {FabricJSCanvas, useFabricJSEditor} from 'fabricjs-react'
import {fabric} from 'fabric';
import {useTheme} from "@emotion/react";
import {faMagnifyingGlassMinus} from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlassMinus";
import {faMagnifyingGlassPlus} from "@fortawesome/pro-regular-svg-icons/faMagnifyingGlassPlus";
import {MapService} from "../../services/mapService";
import {genericError} from "../../functions/genericError";
import {showNotification} from "@mantine/notifications";
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";
import {faChevronLeft} from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import {faChevronRight} from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import {faChevronUp} from "@fortawesome/pro-regular-svg-icons/faChevronUp";
import {faChevronDown} from "@fortawesome/pro-regular-svg-icons/faChevronDown";



const Map = () => {

    const isLight = useTheme().colorScheme === 'light';
    const primaryColor = useTheme().colors.gray[isLight ? 9 : 1];
    const redColor = useTheme().colors.red[isLight ? 6 : 4];

    const [isLoading, setIsLoading] = useState(false)

    const {editor, onReady} = useFabricJSEditor()
    const [fetched, setFetched] = useState(false)
    const [selected, setSelected] = useState()

    const [isSaving, setIsSaving] = useState(false)

    const [selectedValue, setSelectedValue] = useState('')
    const [freeShips, setFreeShips] = useState([])

    const cardRef = useRef();

    useEffect(() => {
        if (fabric && editor && !fetched && onReady) {
            setFetched(true)
            fetchDocks();

            editor.canvas.on({
                'selection:updated': handleElement,
                'selection:created': handleElement,
                'selection:cleared': handleElement
            });
        }
    }, [fabric, editor, onReady])

    useEffect(() => {
        if (fabric && editor && fetched) {
            fetchDocks();
        }
    }, [isLight])

    const loadMap = (r) => {
        setFreeShips(r.freeShips)
        fabric.loadSVGFromURL('/map.svg', function (objects, options) {
            editor.canvas.setHeight(600);
            const items = fabric.util.groupSVGElements(objects, options);
            items.hasControls = false;
            items.selectable = false;
            items._objects.forEach(obj => {
                obj.stroke = primaryColor;
                obj.fill = 'transparent';
            });
            editor.canvas.add(items);


            fabric.util.enlivenObjects(r.boxes.map(o => JSON.parse(o.content)), function (o2) {
                o2.forEach(function (o, index) {
                    const name = r.boxes[index]?.name;
                    const hasShip = name ? r.ships.find(s => s.slot === name) : null;
                    if (hasShip) {
                        o._objects[1]
                            .set('fill', redColor)
                            .set('box_id', name)
                            .set('ship', hasShip.name)
                            .set('text', hasShip.name);
                    }
                    o.hasControls = false;
                    o.selectable = true;
                    editor.canvas.add(o);
                });
            });
            editor.canvas.setZoom(0.25);
            editor.canvas.allowTouchScrolling = true;
            editor.canvas.renderAll();

        })
    }

    const fetchDocks = async () => {
        setIsLoading(true)
        MapService.getMap().then(r => {
            loadMap(r)
        })
            .catch((e) => {
                genericError(e)
            })
            .finally(() => setIsLoading(false))
    }

    const zoomFoo = (x) => {
        let f = editor.canvas.getZoom();
        if (x === 0) {
            f = 0.25;
        } else {
            f += x * 0.1;
            if (f > 2) f = 2;
            if (f < 0.2) f = 0.2;
        }
        editor.canvas.setHeight(2732 * f);
        editor.canvas.setWidth(2048 * f);
        editor.canvas.setZoom(f);
    }

    const handleElement = (obj) => {
        if (obj.selected) {
            setSelected(obj.selected[0])
            setSelectedValue(obj.selected[0]._objects[1].text)
        } else {
            setSelected(undefined)
        }
    }

    useEffect(() => {
        let render = false;
        if (selected) {
            editor.canvas.getActiveObjects().forEach((obj) => {
                const object = obj._objects[1];
                if (object.text !== selectedValue) {
                    object.set('text', selectedValue);
                    render = true;
                }
            });
            if (render) {
                editor.canvas.renderAll()
            }
        }
    }, [selectedValue])


    const updateShipSlot = (ship_id) => {
        const slot = selected._objects[1].text;
        setIsSaving(true)
        MapService.updateShipSlot({ship_id, slot})
            .then(r => {
                loadMap(r)
                showNotification({
                    color: 'teal',
                    icon: <FontAwesomeIcon icon={faCheck}/>,
                    message: 'Mappa aggiornata',
                })
            })
            .catch(genericError)
            .finally(() => setIsSaving(false))
    }

    const freeSlot = () => {
        const slot = selected._objects[1].box_id;
        setIsSaving(true)
        MapService.freeSlot({slot})
            .then(r => {
                loadMap(r)
                showNotification({
                    color: 'teal',
                    icon: <FontAwesomeIcon icon={faCheck}/>,
                    message: 'Mappa aggiornata',
                })
            })
            .catch(genericError)
            .finally(() => setIsSaving(false))
    }


    return <div>
        <Button.Group>
            <Button variant={'outline'} color={'blue'} onClick={() => zoomFoo(-1)}><FontAwesomeIcon
                icon={faMagnifyingGlassMinus}/></Button>
            <Button variant={'outline'} color={'blue'} onClick={() => zoomFoo(0)}>Reset zoom</Button>
            <Button variant={'outline'} color={'blue'} onClick={() => zoomFoo(1)}><FontAwesomeIcon
                icon={faMagnifyingGlassPlus}/></Button>
        </Button.Group>

        <Drawer opened={selected}
                position={'right'}
                padding={16}
                zIndex={1000}
                lockScroll={false}
                onClose={() => setSelected(undefined)}>
            {(selected) && <>
                {selected._objects[1].box_id ?
                    <>
                        <Text>Posto: {selected._objects[1].box_id}</Text>
                        <Text>imbarcazione: {selected._objects[1].text}</Text>

                        <Button variant={'outline'}
                                disabled={isSaving} loading={isSaving}
                                color={'red'} onClick={freeSlot}>Libera posto</Button>
                    </>
                    :
                    <>
                        <Text>Posto: {selected._objects[1].text}</Text>
                        <Select data={freeShips.map(s => ({value: s.id, label: s.name}))} label={'Assegna imbarcazione'} searchable onChange={updateShipSlot}/>
                    </>
                }

            </>}

        </Drawer>

        <Card
            withBorder
            shadow={'sm'}
            style={{
                maxWidth: '100%',
                position: "relative",
                borderRadius: 6,
                padding: 16,
                marginTop: 8
            }}>
            <LoadingOverlay visible={isLoading}/>
            <ActionIcon
                onClick={() => {
                    console.log('click left')
                    cardRef.current.scrollLeft -= 20;
                }}
                style={{
                    position: 'absolute',
                    top: '50%',
                    marginTop: -25,
                    zIndex: 999
                }}>
                <FontAwesomeIcon icon={faChevronLeft}/>
            </ActionIcon>
            <ActionIcon
                onClick={() => {
                    console.log('click right')
                    cardRef.current.scrollLeft += 20;
                }}
                style={{
                    position: 'absolute',
                    top: '50%',
                    marginTop: -25,
                    right: 0,
                    zIndex: 999
                }}>
                <FontAwesomeIcon icon={faChevronRight}/>
            </ActionIcon>
            <ActionIcon
                onClick={() => {
                    cardRef.current.scrollTop -= 20;
                }}
                style={{
                    position: 'absolute',
                    left: '50%',
                    marginLeft: -25,
                    top: 0,
                    zIndex: 999
                }}>
                <FontAwesomeIcon icon={faChevronUp}/>
            </ActionIcon>
            <ActionIcon
                onClick={() => {
                    cardRef.current.scrollTop += 20;
                }}
                style={{
                    position: 'absolute',
                    left: '50%',
                    marginLeft: -25,
                    bottom: 0,
                    zIndex: 999
                }}>
                <FontAwesomeIcon icon={faChevronDown}/>
            </ActionIcon>
            <div ref={cardRef} style={{
                overflow: 'hidden',
                height: 600,
            }}>
                <FabricJSCanvas
                    onReady={onReady}/>
            </div>
        </Card>


    </div>
}
export default Map;