import {Button, Group, Modal, Stack, TextInput} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from '@mantine/form';
import {useEffect, useState} from "react";
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";
import {showNotification} from "@mantine/notifications";
import {genericError} from "../../../functions/genericError";
import {ShipOwnerServicee} from "../../../services/shipOwnerService";

const ModalClientComponent = (props) => {

    const [isSaving, setIsSaving] = useState(false)
    const form = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        criteriaMode: "firstError",
        shouldFocusError: true
    });


    useEffect(() => {
        if (!props.client) return;
        const obj = {
            name: props.client.name,
            surname: props.client.surname,
            given_name: props.client.given_name,
            fiscal_code: props.client.fiscal_code,
            vat_number: props.client.vat_number,
            code: props.client.code,
            email: props.client.email,
            phone: props.client.phone,
            phone_2: props.client.phone_2,
            fax: props.client.fax
        };
        form.setValues(obj)
    }, [props])


    const formOnSubmit = async (values) => {
        setIsSaving(true)
        if (props.client.id) {
            ShipOwnerServicee.update(values, props.client.id)
                .then((r) => {
                    showNotification({
                        color: 'teal',
                        icon: <FontAwesomeIcon icon={faCheck}/>,
                        message: 'Armatore aggiornat*',
                    })
                    props.updateClients('update', r)
                    props.setClient(undefined)

                })
                .catch(genericError)
                .finally(() => {
                    setIsSaving(false)
                })
        } else {
            ShipOwnerServicee.create(values)
                .then((r) => {

                    showNotification({
                        color: 'teal',
                        icon: <FontAwesomeIcon icon={faCheck}/>,
                        message: 'Armatore creat*',
                    })
                    props.updateClients('create', r)
                    props.setClient(undefined)

                })
                .catch(genericError)
                .finally(() => {
                    setIsSaving(false)
                })
        }
    }


    return <Modal
        size={'90%'}
        style={{maxWidth: 600, margin: 'auto'}}
        opened={props.client}
        lockScroll={false}
        onClose={() => props.setClient(undefined)}
        title={props.client?.id ? `Modifica armatore: ${props.client?.name} ${props.client?.surname}` : 'Crea nuovo armatore'}
    >

        <form onSubmit={form.onSubmit(formOnSubmit)}>

            <Stack>
                <TextInput
                    label="Nome"
                    placeholder="Mario"
                    {...form.getInputProps('name')}
                />

                <TextInput
                    label="Cognome"
                    placeholder="Rossi"
                    {...form.getInputProps('surname')}
                />

                <TextInput
                    label="Ragione sociale"
                    placeholder="Mario Rossi"
                    {...form.getInputProps('given_name')}
                />

                <TextInput
                    label="Codice fiscale"
                    placeholder=""
                    {...form.getInputProps('fiscal_code')}
                />

                <TextInput
                    label="Partita IVA"
                    placeholder=""
                    {...form.getInputProps('vat_number')}
                />


                <TextInput
                    label="Codice univoco"
                    placeholder=""
                    {...form.getInputProps('code')}
                />

                <TextInput
                    label="Email"
                    placeholder=""
                    {...form.getInputProps('email')}
                />


                <TextInput
                    label="Telefono"
                    placeholder="+39 312 321443"
                    {...form.getInputProps('phone')}
                />

                <TextInput
                    label="Telefono 2 "
                    placeholder="+39 312 321443"
                    {...form.getInputProps('phone_2')}
                />

                <TextInput
                    label="Fax"
                    placeholder="+39 312 321443"
                    {...form.getInputProps('fax')}
                />

            </Stack>


            <Group position="right" mt="md">
                <Button type="submit"
                        loading={isSaving}
                        disabled={isSaving}>
                    Salva
                </Button>
            </Group>
        </form>

    </Modal>
}
export default ModalClientComponent;
