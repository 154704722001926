import {Text, Button, FileInput, Flex, Group, Modal, Stack, TextInput} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useForm} from '@mantine/form';
import {useEffect, useState} from "react";
import { DatePicker } from '@mantine/dates';
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";
import {showNotification} from "@mantine/notifications";
import {faCross} from "@fortawesome/pro-regular-svg-icons/faCross";
import {faUpload} from "@fortawesome/pro-regular-svg-icons/faUpload";
import {dateToAwsDate} from "../../../functions/dateToAwsDate";
import {DocumentService} from "../../../services/documentService";
import {genericError} from "../../../functions/genericError";





const ModalShipDocumentComponent = (props) => {

    const [isSaving, setIsSaving] = useState(false)
    const form = useForm({
        mode: 'onSubmit',
        reValidateMode: 'onBlur',
        criteriaMode: "firstError",
        shouldFocusError: true
    });
    const [value, setValue] = useState(null); //<File | null>



    useEffect(() => {
        if (value && value.size > 20000000) {
            setValue(null)
            showNotification({
                color: 'red',
                icon: <FontAwesomeIcon icon={faCross}/>,
                title: 'Caricamento file fallito',
                message: `Dimensione massima 20MB, dimensione file selezionato ${Math.round(value.size / 1000000)}MB`,
            })
        }
    }, [value])

    useEffect(() => {
        if (!props.document) return;
        form.setValues({
            type: props.document.type,
            authority: props.document.authority,
            number: props.document.number,
            date_of_issue: props.document.date_of_issue ? new Date(props.document.date_of_issue) : undefined,
            expiration_date: props.document.expiration_date ? new Date(props.document.expiration_date) : undefined,
            unit_type: props.document.unit_type,
            owner: props.document.owner,
            date_of_entry: props.document.date_of_entry ? new Date(props.document.date_of_entry) : undefined,
            date_of_exit: props.document.date_of_exit ? new Date(props.document.date_of_exit) : undefined,
        })

        return () => {
            setValue(null)
        }
    }, [props.document])


    const formOnSubmit = async (values) => {
        setIsSaving(true)


        if(values.date_of_issue){
            values.date_of_issue = dateToAwsDate(values.date_of_issue)
        }
        if(values.expiration_date){
            values.expiration_date = dateToAwsDate(values.expiration_date)
        }
        if(values.date_of_entry){
            values.date_of_entry = dateToAwsDate(values.date_of_entry)
        }
        if(values.date_of_exit){
            values.date_of_exit = dateToAwsDate(values.date_of_exit)
        }

        if(value){
            values.file = value;
        }
        // creazione o aggiornamento solo VALORI
        // nella creazione value è mandatory

        if (props.document.id) {
            DocumentService.update(props.ship.id, values, props.document.id)
                .then((r) => {
                    props.setShip(r)
                    props.setDocument(undefined)
                })
                .catch(genericError)
                .finally(() => {
                    setIsSaving(false)
                })
        } else {
            DocumentService.create(props.ship.id, values)
                .then((r) => {
                    props.setShip(r)
                    props.setDocument(undefined)
                })
                .catch(genericError)
                .finally(() => {
                    setIsSaving(false)
                })
        }
    }


    return <Modal
        size={'90%'}
        lockScroll={false}
        opened={props.document}
        onClose={() => props.setDocument(undefined)}
        title={props.document?.id ? `Modifica documento:` : 'Aggiungi nuovo documento'}
    >

        <form onSubmit={form.onSubmit(formOnSubmit)}>

            <Stack>
                <Flex align={'flex-end'} justify={'space-between'}>
                    <Text>{props.document?.file ? props.document.file.fileName : ''}</Text>
                    <FileInput
                        value={value} onChange={setValue}
                        style={{flex: 0.95}}
                        accept="image/png,image/jpeg,image/jpg,image/svg,image/gif,application/pdf"
                        label="File da allegare"
                        placeholder="Sfoglia"
                        icon={<FontAwesomeIcon icon={faUpload}/>}/>
                </Flex>
                <TextInput
                    label="Tipo documento"
                    placeholder=""
                    {...form.getInputProps('type')}
                />

                <TextInput
                    style={{flex: 0.45}}
                    label="Titolare documento"
                    placeholder=""
                    {...form.getInputProps('owner')}
                />

                <TextInput
                    label="Rilasciato da"
                    placeholder=""
                    {...form.getInputProps('authority')}
                />

                <TextInput
                    style={{flex: 0.45}}
                    label="Numero documento"
                    placeholder=""
                    {...form.getInputProps('number')}
                />

                <Flex justify={'space-between'}>
                    <DatePicker
                        withinPortal={true}
                        locale={'it'}
                        style={{flex: 0.45}}
                        label="Rilasciato il"
                        inputFormat="YYYY-MM-DD"
                        placeholder=""
                        {...form.getInputProps('date_of_issue')}
                    />

                    <DatePicker
                        withinPortal={true}
                        locale={'it'}
                        style={{flex: 0.45}}
                        label="Valido fino al"
                        inputFormat="YYYY-MM-DD"
                        placeholder=""
                        {...form.getInputProps('expiration_date')}
                    />
                </Flex>


                <Flex justify={'space-between'}>
                    <DatePicker
                        withinPortal={true}
                        locale={'it'}
                        style={{flex: 0.45}}
                        label="Data ingresso"
                        inputFormat="YYYY-MM-DD"
                        placeholder=""
                        {...form.getInputProps('date_of_entry')}
                    />
                    <DatePicker
                        withinPortal={true}
                        locale={'it'}
                        style={{flex: 0.45}}
                        label="Data uscita"
                        inputFormat="YYYY-MM-DD"
                        placeholder=""
                        {...form.getInputProps('date_of_exit')}
                    />
                </Flex>

            </Stack>

            <Group position="right" mt="md">
                <Button type="submit"
                        loading={isSaving}
                        disabled={isSaving}>
                    Salva
                </Button>
            </Group>
        </form>

    </Modal>
}
export default ModalShipDocumentComponent;
