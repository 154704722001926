import {useLocation, useNavigate} from "react-router-dom";
import {faUsers} from "@fortawesome/pro-regular-svg-icons/faUsers";
import {faSailboat} from "@fortawesome/pro-regular-svg-icons/faSailboat";
import {faMap} from "@fortawesome/pro-regular-svg-icons/faMap";
import {Button, Flex, Tooltip} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendarAlt} from "@fortawesome/pro-regular-svg-icons/faCalendarAlt";

const FooterComponent = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const tabs = [
        {
            title: 'Clienti',
            icon: faUsers,
            key: '/clients'
        },
        {
            title: 'Imbarcazioni',
            icon: faSailboat,
            key: '/ships'
        },
        {
            title: 'Mappa cantiere',
            icon: faMap,
            key: '/map'
        },
        {
            title: 'Calendario',
            icon: faCalendarAlt,
            key: '/events'
        },
    ]

    return <Flex justify={'space-around'}>
        {tabs.map(item => (
            <Tooltip key={item.key} label={item.title} withinPortal>
                <Button size={'lg'}
                        onClick={() => navigate(item.key)}
                        variant={location.pathname === item.key ? 'filled' : 'outline'}>
                    <FontAwesomeIcon icon={item.icon} size={'xl'} title={item.title}/>
                </Button>
            </Tooltip>
        ))}

    </Flex>/*
    return <TabBar safearea={true} style={{background: '#f2f2f3', minHeight: 60}}
                   activeKey={location.pathname}
                   }>
        {tabs.map(item => (
            <TabBar.Item key={item.key} icon={<FontAwesomeIcon icon={item.icon} size={'xl'} title={item.title}/>}/>
        ))}
    </TabBar>*/
}
export default FooterComponent;