import {Button, Flex, LoadingOverlay, Text, TextInput, Title, Tooltip} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch} from "@fortawesome/pro-regular-svg-icons/faSearch";
import React, {useEffect, useRef, useState} from "react";
import {useDebouncedValue, useFocusTrap, useMergedRef, useViewportSize} from "@mantine/hooks";
import {faFileInvoiceDollar} from "@fortawesome/pro-regular-svg-icons/faFileInvoiceDollar";
import sortBy from "lodash/sortBy";
import {EstimateService} from "../../services/estimateService";
import {faPlus} from "@fortawesome/pro-regular-svg-icons/faPlus";
import ModalEstimateComponent from "./components/ModalEstimate.component";
import {DataTable} from "mantine-datatable";
import {faScrewdriverWrench} from "@fortawesome/pro-regular-svg-icons/faScrewdriverWrench";
import {genericError} from "../../functions/genericError";
import {useNavigate} from "react-router-dom";
import {faEye} from "@fortawesome/pro-regular-svg-icons/faEye";
import {faCopy} from "@fortawesome/pro-regular-svg-icons/faCopy";
import {openConfirmModal} from "@mantine/modals";


const initialState = {}

const Estimates = () => {

    const PAGE_SIZE = 10;

    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [estimates, setEstimates] = useState([])
    const [initialEstimates, setInitialEstimates] = useState([])
    const [size, setSize] = useState(0)
    const [page, setPage] = useState(1)


    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);
    const [sortStatus, setSortStatus] = useState({columnAccessor: 'name', direction: 'asc'});

    const searchRef = useRef();
    const focusTrapRef = useFocusTrap(true);
    const mergedRef = useMergedRef(searchRef, focusTrapRef)

    const [modalEstimate, setModalEstimate] = useState();
    const [selectedEstimates, setSelectedEstimates] = useState([]);
    const [isSaving, setIsSaving] = useState(false);

    const reset = () => {
        setEstimates(initialEstimates)
        searchRef.current.value = '';
        setPage(1)
        setQuery('')
    }

    async function fetchEstimates(silently = false) {
        if (!silently) {
            setIsLoading(true)
        }
        EstimateService.list().then((r) => {
            setEstimates(r)
            setInitialEstimates(r)
            setSize(r.length)
        })
            .catch(genericError)
            .finally(() => setIsLoading(false))

    }

    useEffect(() => {
        if (initialEstimates.length === 0) {
            return;
        }
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setEstimates(initialEstimates.slice(from, to));
        setSize(initialEstimates.length)
    }, [page, initialEstimates]);

    useEffect(() => {
        fetchEstimates();
    }, [])


    useEffect(() => {
        if (debouncedQuery === '') {
            reset();
            return;
        }
        setEstimates(
            initialEstimates.filter((estimate) => {
                const ship_owner_name = estimate.ship_owner?.given_name ?? estimate.ship_owner?.surname + ' ' + estimate.ship_owner?.name;
                const estimate_name = estimate.name ?? '';
                if (
                    estimate_name.toLowerCase().includes(debouncedQuery.trim().toLowerCase()) ||
                    ship_owner_name.toLowerCase().includes(debouncedQuery.trim().toLowerCase()) ||
                    estimate.valid_until.toLowerCase().includes(debouncedQuery.trim().toLowerCase())
                ) {
                    return true;
                }
                return false;
            })
        );
    }, [debouncedQuery]);

    useEffect(() => {
        const data = sortBy(estimates, sortStatus.columnAccessor);
        setEstimates(sortStatus.direction === 'desc' ? data.reverse() : data);
    }, [sortStatus]);


    const updateEstimates = (method, element) => {
        let clone = [...estimates];
        let clone2 = [...initialEstimates];
        if (method !== 'create') {
            clone = clone.filter(i => i.id !== element.id);
            clone2 = clone2.filter(i => i.id !== element.id);
        }
        if (method === 'delete') {
            setEstimates([...clone])
            setInitialEstimates([...clone2])
        } else {
            setEstimates([element, ...clone])
            setInitialEstimates([element, ...clone2])
        }
    }

    const {width} = useViewportSize();

    const askConfirmClone = (item) => openConfirmModal({
        title: 'Conferma duplicazione',
        children: (
            <Text size="sm">
                Sei sicuro di voler duplicare questi preventivi?
            </Text>
        ),
        labels: {confirm: 'Conferma', cancel: 'Annulla'},
        confirmProps: {color: 'teal'},
        onCancel: () => console.log('Azione annullata'),
        onConfirm: () => cloneItems(item),
    });

    const cloneItems = () => {
        setIsSaving(true)
        EstimateService.cloneSelected({
            items: selectedEstimates.map((i) => i.id)
        })
            .then((r) => {
                setEstimates(r)
            })
            .catch(genericError)
            .finally(() => setIsSaving(false))
    }

    return <>

        <Flex justify={'space-between'} align={'center'}>
            <Title order={1}><FontAwesomeIcon icon={faFileInvoiceDollar} style={{marginRight: 15}}/> Scheda
                preventivi</Title>

            <Button.Group>
                <Tooltip label="Crea preventivo" withinPortal>
                    <Button onClick={() => setModalEstimate(initialState)} disabled={isLoading || isSaving}>
                        <FontAwesomeIcon size={'xl'} icon={faPlus}/>
                    </Button>
                </Tooltip>

                <Tooltip label="Copia preventivi selezionati" withinPortal>
                    <Button color={'teal'} onClick={askConfirmClone} disabled={isLoading || isSaving}>
                        <FontAwesomeIcon size={'xl'} icon={faCopy}/>
                    </Button>
                </Tooltip>
            </Button.Group>
        </Flex>


        <div style={{marginTop: 24, marginBottom: 24}}>
            <TextInput
                ref={mergedRef}
                onChange={e => {
                    setQuery(e.target.value)
                    if (e.target.value === '') {
                        reset();
                    }
                }}
                rightSectionProps={<FontAwesomeIcon icon={faSearch}/>}
                placeholder=""
                label="Cerca preventivo per data oppure armatore (nome / cognome o ragione sociale)"
            />
        </div>

        <ModalEstimateComponent estimate={modalEstimate} setEstimate={setModalEstimate}
                                updateEstimates={updateEstimates}/>
        <div style={{position: 'relative'}}>
            <LoadingOverlay visible={isLoading || isSaving} overlayBlur={2}/>
            <DataTable
                minHeight={400}
                selectedRecords={selectedEstimates}
                onSelectedRecordsChange={setSelectedEstimates}
                withBorder
                sortStatus={sortStatus}
                onSortStatusChange={setSortStatus}
                records={estimates}
                columns={[
                    {
                        accessor: 'number', sortable: true, title: <div style={{paddingLeft: 12}}>Numero</div>,
                        render: (element) => <div style={{paddingLeft: 16}}>{element.number ?? element.id}</div>
                    },
                    {
                        accessor: 'ship', sortable: true, title: <div>Imbarcazione</div>,
                        render: (element) => <div>{element.ship?.name}</div>
                    },
                    {
                        accessor: 'ship_owner', sortable: true, title: 'Armatore',
                        render: (element) => <>{element.ship_owner?.given_name ?? element.ship_owner?.surname + ' ' + element.ship_owner?.name}</>
                    },
                    {
                        accessor: 'Azioni',
                        width: width <= 1200 ? 100 : 240,
                        render: (element) => <Button.Group orientation={width <= 1200 ? 'vertical' : 'horizontal'}>
                            <Tooltip label="Dettagli preventivo" withinPortal>
                                <Button variant="light"
                                        onClick={() => navigate(`/estimate?id=${element.id}`)}>
                                    <FontAwesomeIcon icon={faEye}/>
                                </Button>
                            </Tooltip>
                            <Tooltip label="Modifica preventivo" withinPortal>
                                <Button variant="light"
                                        onClick={() => setModalEstimate(element)}>
                                    <FontAwesomeIcon icon={faScrewdriverWrench}/>
                                </Button>
                            </Tooltip>
                        </Button.Group>
                    },
                ]}
                totalRecords={size}
                striped
                highlightOnHover
                recordsPerPage={PAGE_SIZE}

                //idAccessor={'id'}
                page={page}
                onPageChange={(p) => setPage(p)}
                loadingText="Caricamento in corso..."
                noRecordsText="Nessun risultato trovato"
                // uncomment the next line to use a custom pagination text
                // paginationText={({ from, to, totalRecords }) => `Records ${from} - ${to} of ${totalRecords}`}
                // uncomment the next line to use a custom pagination color (see https://mantine.dev/theming/colors/)
                // paginationColor="grape"
                // uncomment the next line to use a custom pagination size
                // paginationSize="md"
            />
        </div>


    </>
}
export default Estimates;