import {AxiosInterceptor} from "./AxiosInterceptor";

export class WarehouseService {



    static list = async (date) => {
        const url = `${process.env.REACT_APP_URL}/warehouse/list`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.get(url, {date: date}).then((res) => {
                    return res.data;
                });
            });
    }

    static create = async (data) => {
        const url = `${process.env.REACT_APP_URL}/warehouse`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.post(url, data).then((res) => {
                    return res.data;
                });
            });
    }

    static update = async (data, id) => {
        const url = `${process.env.REACT_APP_URL}/warehouse/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.put(url, data).then((res) => {
                    return res.data;
                });
            });
    }

    static delete = async (id) => {
        const url = `${process.env.REACT_APP_URL}/warehouse/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.delete(url).then((res) => {
                    return res.data;
                });
            });
    }



}