import {AxiosInterceptor} from "./AxiosInterceptor";
import axios from 'axios';

export class EstimateService {

    static get = async (id) => {
        const url = `${process.env.REACT_APP_URL}/estimate/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.get(url).then((res) => {
                    return res.data;
                });
            });
    }

    static list = async () => {
        const url = `${process.env.REACT_APP_URL}/estimates`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.get(url).then((res) => {
                    return res.data;
                });
            });
    }

    static cloneSelected = async (items) => {
        const url = `${process.env.REACT_APP_URL}/estimates/clone`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.post(url, items).then((res) => {
                    return res.data;
                });
            });
    }

    static create = async (data) => {
        const url = `${process.env.REACT_APP_URL}/estimate`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.post(url, data).then((res) => {
                    return res.data;
                });
            });
    }

    static update = async (data, id) => {
        const url = `${process.env.REACT_APP_URL}/estimate/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.put(url, data).then((res) => {
                    return res.data;
                });
            });
    }

    static delete = async (id) => {
        const url = `${process.env.REACT_APP_URL}/estimate/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.delete(url).then((res) => {
                    return res.data;
                });
            });
    }

    static share = async (id, data) => {
        const url = `${process.env.REACT_APP_URL}/shareEstimate/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.post(url, data).then((res) => {
                    return res.data;
                });
            });
    }

    static getPreview = async (id) => {
        const url = `${process.env.REACT_APP_URL}/previewEstimate/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.get(url).then((res) => {
                    return res.data;
                });
            });
    }


    static downloadPdf = async (id) => {
        const url = `${process.env.REACT_APP_URL}/downloadEstimate/${id}`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.get(url, {
                    responseType: 'blob'
                }).then((res) => {
                    return res.data;
                });
            });
    }

    static downloadPdfNotes = async (id) => {
        const url = `${process.env.REACT_APP_URL}/downloadEstimateNotes`;
        return await AxiosInterceptor().then(
            (ax) => {
                return ax.get(url).then((res) => {
                    return res.data;
                });
            });
    }


}