import React, {useEffect, useRef, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUserPlus} from "@fortawesome/pro-regular-svg-icons/faUserPlus";
import {faScrewdriverWrench} from "@fortawesome/pro-regular-svg-icons/faScrewdriverWrench";
import {faUsers} from "@fortawesome/pro-regular-svg-icons/faUsers";
import {useNavigate} from "react-router-dom";
import {Text, Title, Button, TextInput, Flex, LoadingOverlay, Tooltip, List} from "@mantine/core";
import {useDebouncedValue, useFocusTrap, useMergedRef, useViewportSize} from "@mantine/hooks";
import {faSearch} from "@fortawesome/pro-regular-svg-icons/faSearch";
import {faSailboat} from "@fortawesome/pro-regular-svg-icons/faSailboat";
import {openConfirmModal} from "@mantine/modals";
import {faFiles} from "@fortawesome/pro-regular-svg-icons/faFiles";
import ModalShipComponent from "./components/ModalShip.component";
import ModalAddClientComponent from "./components/ModalAddClient.component";
import {faTrash} from "@fortawesome/pro-regular-svg-icons/faTrash";
import {ShipService} from "../../services/shipService";
import {DataTable} from "mantine-datatable";
import sortBy from 'lodash/sortBy';
import {genericError} from "../../functions/genericError";
import {showNotification} from "@mantine/notifications";
import {ShipOwnerServicee} from "../../services/shipOwnerService";
import {faPlus} from "@fortawesome/pro-regular-svg-icons/faPlus";


const initialState = {
    name: '',
    flag: '',
    length: '',
    width: '',
    height: '',
    imo: '',
    brand: '',
    model: '',
    commander: '',
    propulsion: '',
    engine: '',
}

const Ships = () => {

    const PAGE_SIZE = 10;
    const navigate = useNavigate();
    const [initialShips, setInitialShips] = useState([])
    const [ships, setShips] = useState([])
    const [size, setSize] = useState(0)

    const [isLoading, setIsLoading] = useState(false)

    const [query, setQuery] = useState('');
    const [debouncedQuery] = useDebouncedValue(query, 200);

    const [sortStatus, setSortStatus] = useState({columnAccessor: 'name', direction: 'asc'});

    const [showModalAddClientComponent, setShowModalAddClientComponent] = useState(undefined)
    const [showModalShip, setShowModalShip] = useState(undefined)
    const searchRef = useRef();
    const focusTrapRef = useFocusTrap(true);
    const mergedRef = useMergedRef(searchRef, focusTrapRef)

    const [deletingShipId, setDeletingShipId] = useState()

    const [removingClientId, setRemovingClientId] = useState()
    const [page, setPage] = useState(1)

    const {width} = useViewportSize();

    const reset = () => {
        setShips(initialShips)
        searchRef.current.value = '';
        setPage(1)
        setQuery('')
    }


    async function fetchShips(silently = false) {
        if (!silently) {
            setIsLoading(true)
        }
        ShipService.list().then((r) => {
            setShips(r)
            setInitialShips(r)
            setSize(r.length)
        }).finally(() => setIsLoading(false))

    }

    const updateShips = (method, element) => {
        let clone = [...ships];
        let clone2 = [...initialShips];
        if (method !== 'create') {
            clone = clone.filter(i => i.id !== element.id);
            clone2 = clone2.filter(i => i.id !== element.id);
        }
        if (method === 'delete') {
            setShips([...clone])
            setInitialShips([...clone2])
        } else {
            setShips([element, ...clone])
            setInitialShips([element, ...clone2])
        }
    }


    useEffect(() => {
        if (initialShips.length === 0) {
            return;
        }
        const from = (page - 1) * PAGE_SIZE;
        const to = from + PAGE_SIZE;
        setShips(initialShips.slice(from, to));
        setSize(initialShips.length)
    }, [page, initialShips]);

    useEffect(() => {
        fetchShips();
    }, [])

    useEffect(() => {
        if (debouncedQuery === '') {
            reset();
            return;
        }
        setShips(
            initialShips.filter((ship) => {
                if (
                    ship.name.toLowerCase().includes(debouncedQuery.trim().toLowerCase()) ||
                    (ship.model && ship.model.toLowerCase().includes(debouncedQuery.trim().toLowerCase())) ||
                    (ship.slot && ship.slot.toLowerCase().includes(debouncedQuery.trim().toLowerCase()))
                ) {
                    return true;
                }
                return false;
            })
        );
    }, [debouncedQuery]);

    useEffect(() => {
        const data = sortBy(ships, sortStatus.columnAccessor);
        setShips(sortStatus.direction === 'desc' ? data.reverse() : data);
    }, [sortStatus]);


    const confirmDeleteShip = (ship) => openConfirmModal({
        title: 'Conferma eliminazione',
        children: (
            <Text size="sm">
                Sei sicuro di voler eliminare l'imbarcazione {ship.name}?
            </Text>
        ),
        labels: {confirm: 'Conferma', cancel: 'Annulla'},
        zIndex: 138,
        confirmProps: {color: 'red'},
        onCancel: () => console.log('Azione annullata'),
        onConfirm: () => deleteShipFunction(ship),
    });

    const deleteShipFunction = async (ship) => {
        setDeletingShipId(ship.id)
        ShipService.delete(ship.id)
            .then(() => {
                showNotification({
                    color: 'teal',
                    icon: <FontAwesomeIcon icon={faTrash}/>,
                    message: 'Imbarcazione eliminata',
                })
                updateShips('delete', ship)
            })
            .catch(genericError)
            .finally(() => {
                setDeletingShipId(undefined)
            })
    }


    async function removeClientFromShip(ship, shipOwner) {
        setRemovingClientId(shipOwner.id)
        ShipService.removeClient(ship.id, shipOwner.id)
            .then((r) => {
                updateShips('update', r)
            })
            .catch(genericError)
            .finally(() => setRemovingClientId(undefined))
    }


    return <>
        <Flex justify={'space-between'} align={'center'}>
            <Title order={1}><FontAwesomeIcon icon={faSailboat} style={{marginRight: 15}}/> Scheda imbarcazioni</Title>

            <Tooltip label="Aggiungi imbarcazione" withinPortal>
                <Button onClick={() => setShowModalShip(initialState)} disabled={isLoading}>
                    <FontAwesomeIcon size={'xl'} icon={faPlus}/>
                </Button>
            </Tooltip>
        </Flex>

        <div style={{marginTop: 24, marginBottom: 24}}>
            <TextInput
                ref={mergedRef}
                onChange={e => {
                    setQuery(e.target.value)
                    if (e.target.value === '') {
                        reset();
                    }
                }}
                rightSectionProps={<FontAwesomeIcon icon={faSearch}/>}
                placeholder="Ice Breaker"
                label="Cerca imbarcazione per nome o modello oppure slot"
            />
        </div>


        <div style={{position: 'relative'}}>
            <LoadingOverlay visible={isLoading} overlayBlur={2}/>
            <DataTable
                minHeight={400}
                withBorder
                sortStatus={sortStatus}
                onSortStatusChange={setSortStatus}
                records={ships}
                columns={[
                    {accessor: 'name', sortable: true, title: 'Nome'},
                    {accessor: 'model', sortable: true, title: 'Modello'},
                    {accessor: 'slot', sortable: true, title: 'Posto barca', width: 100},
                    {
                        accessor: 'ship_owners',
                        title: 'Armatori',
                        render: (ship) => Array.isArray(ship.ship_owners) ? <List>
                            {ship.ship_owners.map((so, i) => <List.Item key={i} className={'fullWidthListItem'}>
                                <Flex justify={'space-between'}>
                                    {so.given_name ?? so.surname + ' ' + so.name}
                                    <Button color={'red'}
                                            variant={'outline'}
                                            size={'xs'}
                                            compact
                                            disabled={so.id === removingClientId}
                                            loading={so.id === removingClientId}
                                            onClick={() => removeClientFromShip(ship, so)}>
                                        <FontAwesomeIcon icon={faTrash}/>
                                    </Button>
                                </Flex>
                            </List.Item>)}
                        </List> : 'Nessun armatore',
                    },

                    {
                        accessor: 'Azioni',
                        width: width <= 1200 ? 100 : 240,
                        render: (element) => <Button.Group orientation={width <= 1200 ? 'vertical' : 'horizontal'}>
                            <Tooltip label="Aggiungi armatore" withinPortal>
                                <Button variant="light"
                                        onClick={() => setShowModalAddClientComponent(element)}>
                                    <FontAwesomeIcon icon={faUserPlus}/>
                                </Button>
                            </Tooltip>
                            <Tooltip label="Modifica imbarcazione" withinPortal>
                                <Button variant="light"
                                        onClick={() => setShowModalShip(element)}>
                                    <FontAwesomeIcon icon={faScrewdriverWrench}/>
                                </Button>
                            </Tooltip>
                            <Tooltip label="Allega documenti" withinPortal>
                                <Button variant="light"
                                        onClick={() => navigate(`/ship/${element.id}/documents`)}>
                                    <FontAwesomeIcon icon={faFiles}/>
                                </Button>
                            </Tooltip>

                            <Tooltip label="Elimina imbarcazione" withinPortal>
                                <Button variant="light" color={'red'}
                                        disabled={deletingShipId === element.id}
                                        loading={deletingShipId === element.id}
                                        onClick={() => confirmDeleteShip(element)}>
                                    <FontAwesomeIcon icon={faTrash}/>
                                </Button>
                            </Tooltip>
                        </Button.Group>
                    },
                ]}
                totalRecords={size}
                striped
                highlightOnHover
                recordsPerPage={PAGE_SIZE}
                //idAccessor={'id'}
                page={page}
                onPageChange={(p) => setPage(p)}
                loadingText="Caricamento in corso..."
                noRecordsText="Nessun risultato trovato"
                // uncomment the next line to use a custom pagination text
                // paginationText={({ from, to, totalRecords }) => `Records ${from} - ${to} of ${totalRecords}`}
                // uncomment the next line to use a custom pagination color (see https://mantine.dev/theming/colors/)
                // paginationColor="grape"
                // uncomment the next line to use a custom pagination size
                // paginationSize="md"
            />
        </div>

        <ModalShipComponent
            ship={showModalShip}
            updateShips={updateShips}
            setShip={setShowModalShip}/>

        <ModalAddClientComponent
            ship={showModalAddClientComponent}
            setShip={setShowModalAddClientComponent}
            updateShips={updateShips}
        />

    </>
}
export default Ships;