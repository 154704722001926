import {useEffect, useState} from "react";
import {useForm} from "@mantine/form";
import {
    Anchor, Box, Button, Center, Container, createStyles,
    Group,
    List,
    Paper,
    PasswordInput,
    Progress,
    Stack,
    Stepper,
    Text,
    TextInput,
    Title
} from "@mantine/core";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCircleCheck} from "@fortawesome/pro-regular-svg-icons/faCircleCheck";
import {faCircle} from "@fortawesome/pro-regular-svg-icons/faCircle";
import {faChevronLeft} from "@fortawesome/pro-regular-svg-icons/faChevronLeft";
import {faEnvelope} from "@fortawesome/pro-regular-svg-icons/faEnvelope";
import {faInputNumeric} from "@fortawesome/pro-regular-svg-icons/faInputNumeric";
import {useNavigate} from "react-router-dom";
import {Auth} from "aws-amplify";
import {showNotification} from "@mantine/notifications";
import {faCheck} from "@fortawesome/pro-regular-svg-icons/faCheck";
import {faCross} from "@fortawesome/pro-regular-svg-icons/faCross";

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: 26,
        fontWeight: 900,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    },

    controls: {
        [theme.fn.smallerThan('xs')]: {
            flexDirection: 'column-reverse',
        },
    },

    control: {
        [theme.fn.smallerThan('xs')]: {
            width: '100%',
            textAlign: 'center',
        },
    },
}));


const ResetPassword = (props) => {

    const navigate = useNavigate();
    const {classes} = useStyles()
    const [isLoading, setIsLoading] = useState(false)

    const [percentage, setPercentage] = useState(0);
    const [validDigit, setValidDigit] = useState(false);
    const [validUppercase, setValidUppercase] = useState(false);
    const [validLowercase, setValidLowercase] = useState(false);
    const [validSpecialCharacters, setValidSpecialCharacters] = useState(false);
    const [validLen, setValidLen] = useState(false);

    const [validPassword, setValidPassword] = useState(false);

    const form = useForm({
        initialValues: {
            newPassword: '',
            confirmNewPassword: '',
        },

        validate: {
            newPassword: (value) => (validPassword) ? null : 'La lunghezza minima della password è di 8 caratteri',
            confirmNewPassword: (value) => (form.values.newPassword === form.values.confirmNewPassword) ? null : 'La lunghezza minima della password è di 8 caratteri',
        },
    });

    useEffect(() => {
        let newPercentage = 0;

        const password = form.values.newPassword;

        if (password && password.length > 0) {
            const newValidDigit = new RegExp(/\d/).test(password);
            const newValidUppercase = new RegExp(/[A-Z]/).test(password);
            const newValidLowercase = new RegExp(/[a-z]/).test(password);
            const newValidSpecialCharacters = new RegExp(/\W/).test(password);
            const newValidLen = password.length >= 9 && password.length <= 99;

            setValidDigit(newValidDigit); // async
            setValidUppercase(newValidUppercase);  // async
            setValidLowercase(newValidLowercase);  // async
            setValidSpecialCharacters(newValidSpecialCharacters);  // async
            setValidLen(newValidLen);  // async

            setValidPassword(
                newValidDigit &&
                newValidUppercase &&
                newValidLowercase &&
                newValidSpecialCharacters &&
                newValidLen
            )

            newPercentage = (newValidDigit ? 0.2 : 0) +
                (newValidUppercase ? 0.2 : 0) +
                (newValidLowercase ? 0.2 : 0) +
                (newValidSpecialCharacters ? 0.2 : 0) +
                (newValidLen ? 0.2 : 0)

        } else {
            setValidDigit(false);
            setValidUppercase(false);
            setValidLowercase(false);
            setValidSpecialCharacters(false);
            setValidLen(false);
        }

        setPercentage(newPercentage * 100); // async
    }, [form.values.newPassword])

    const onFormSubmit = (values) => {
        setIsLoading(true)
            Auth.completeNewPassword(props.user, values.newPassword)
                .then((res) => {
                    showNotification({
                        color: 'teal',
                        icon: <FontAwesomeIcon icon={faCheck}/>,
                        message: 'Password aggiornata correttamente'
                    })
                    props.setAuthState('SIGNED_IN')
                }).catch((error) => {
                showNotification({
                    color: 'red',
                    icon: <FontAwesomeIcon icon={faCross}/>,
                    message: error.message
                })
            }).finally(() => {
                setIsLoading(false)
            });
    }

    return <Container size={460} my={30}>
        <Title className={classes.title} align="center">
            È necessario impostare una nuova password
        </Title>

        <Paper withBorder shadow="md" p={30} mt={30} radius="md">

            <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
                <Stack>

                    <div>
                        <PasswordInput label="Nuova password"
                                       description={<>
                                           <Text>La password deve contenere:</Text>
                                           <List size={'xs'}>
                                               <List.Item
                                                   icon={<FontAwesomeIcon icon={validLen ? faCircleCheck : faCircle}
                                                                          color={validLen ? 'teal' : ''}/>}>8
                                                   caratteri</List.Item>
                                               <List.Item icon={<FontAwesomeIcon
                                                   icon={validUppercase ? faCircleCheck : faCircle}
                                                   color={validUppercase ? 'teal' : ''}/>}>almeno una lettera
                                                   maiuscola</List.Item>
                                               <List.Item icon={<FontAwesomeIcon
                                                   icon={validLowercase ? faCircleCheck : faCircle}
                                                   color={validLowercase ? 'teal' : ''}/>}>almeno una lettera
                                                   minuscola</List.Item>
                                               <List.Item
                                                   icon={<FontAwesomeIcon icon={validDigit ? faCircleCheck : faCircle}
                                                                          color={validDigit ? 'teal' : ''}/>}>almeno una
                                                   numero</List.Item>
                                               <List.Item icon={<FontAwesomeIcon
                                                   icon={validSpecialCharacters ? faCircleCheck : faCircle}
                                                   color={validSpecialCharacters ? 'teal' : ''}/>}>almeno un carattere
                                                   speciale</List.Item>
                                           </List>
                                       </>}
                                       required
                                       {...form.getInputProps('newPassword')}/>
                        <Progress color="cyan" radius="xs" size="sm" value={percentage}/>
                    </div>
                    <PasswordInput label="Conferma nuova password"
                                   required   {...form.getInputProps('confirmNewPassword')}/>

                </Stack>

                <Group position="apart" mt="lg" className={classes.controls}>
                    <Anchor color="dimmed" size="sm" className={classes.control} onClick={() => {
                        props.setAuthState('SIGN_IN')
                    }}>
                        <Center inline>
                            <FontAwesomeIcon icon={faChevronLeft}/>
                            <Box ml={5}>Torna alla login</Box>
                        </Center>
                    </Anchor>
                    <Button className={classes.control}
                            leftIcon={<FontAwesomeIcon icon={faCircleCheck}/>}
                            type={'submit'}
                            loading={isLoading}
                            disabled={isLoading}>
                        Imposta nuova password
                    </Button>
                </Group>
            </form>
        </Paper>
    </Container>
}
export default ResetPassword